import store from "@/store";
import IBAN from "iban";
import axios from "axios";
import moment from "moment";
import { helpers } from "vuelidate/lib/validators";
const cf_lib = require("codice-fiscale-js");

// Preload blocked iban
/* Todo: refactor with constants */
let blockedIBAN = []
axios.get('https://cofidis-settings.s3.eu-central-1.amazonaws.com/iban_prepaid.json').then(function (response) {
    blockedIBAN = response.data
})

export const getBirthday = () => {
    let birthday = store.getters["fiscalCodeExtracted"].birthday;
    if (birthday === null) return "01/1900";
    birthday = birthday.split("-");

    return `${birthday[1]}/${birthday[0]}`;
};

export const mapUserData = arr => {
    let mapped = {};
    arr.forEach(field => {
        mapped[field] = {
            get() {
                return this.$store.state.userData[field];
            },
            set(value) {
                this.$store.commit("setUserData", { key: field, value });
            }
        };
    });
    return mapped;
};

export const nonZero = field => {
    return parseInt(field) !== 0;
};

export const nonZeroIf = locator => {
    return (field, vm) => {
        if (!vm[locator]) return true;
        return parseInt(field) !== 0;
    };
};

export const validIBAN = value => {        
    return IBAN.isValid(value)
};

export const notPrepaidIBAN = value => {
    // always validate if theme config prepaidCards is set
    if (store.state.prepaidCards) return true
      
    if(!value) return false;
    
    const ABI = value.replace(/ +/g, "").substring(5, 10)
    const CAB = value.replace(/ +/g, "").substring(10, 15)
    const CC = value.replace(/ +/g, "").slice(15, 27)

    // test if iban match prepaid iban cards
    const isPrepaid = blockedIBAN.map(iban => {
        iban.Abi = iban.Abi.toString()
        iban.Cab = iban.Cab.toString()
        iban.CC = iban.CC.toString()

        if(iban.CC && iban.Cab && iban.Abi)
            return iban.Abi == ABI && iban.Cab == CAB && iban.CC == CC.substring(0, iban.CC.length)
        if(iban.Abi && iban.Cab)
            return iban.Abi == ABI && iban.Cab == CAB
        if(iban.Abi && iban.CC)
            return iban.Abi == ABI && iban.CC == CC.substring(0, iban.CC.length)

        return false
    })
    return !isPrepaid.includes(true)
};

export const validPhone = value => {
    let re = /^(\+39[. ]??)??3\d{2}[. ]??\d{6,7}$/    
    return re.test(value)
}

export const extractFiscalCode = code => {
    try {
        let cf_data = cf_lib.computeInverse(code);
        cf_data.country =
            cf_data.birthplaceProvincia === "EE" ? cf_data.birthplace : "ITALIA";
        cf_data.city =
            cf_data.birthplaceProvincia === "EE" ? "EE" : cf_data.birthplace;
        return cf_data;
    } catch (e) {
        return {
            birthday: null,
            birthplace: null,
            birthplaceProvincia: null,
            cf: code,
            day: null,
            gender: null,
            month: null,
            name: null,
            surname: null,
            year: null
        };
    }
};

export const fiscal_code_valid = code => {
    return !helpers.req(code) || cf_lib.check(code);
};

export const fiscal_code_name = (code, vm) => {
    if (!helpers.req(code)) return true;
    if (!fiscal_code_valid(code)) return true;
    let cf_data = cf_lib.computeInverse(code);

    // Add exception for wrong code Z254    
    if(cf_data.birthplace == 'GEORGIA') cf_data.birthplace = 'SERBIA'

    const cf = cf_lib.compute({
        name: vm.name,
        surname: vm.surname,
        gender: cf_data.gender,
        day: cf_data.day,
        month: cf_data.month,
        year: cf_data.year,
        birthplace: cf_data.birthplace,
        birthplaceProvincia: cf_data.birthplaceProvincia
    });

    return cf.substr(0, 6) === code.substr(0, 6);
};

export const fiscal_code_age = (code) => {
    if (!helpers.req(code)) return true;
    if (!fiscal_code_valid(code)) return true;
    let cf_data = cf_lib.computeInverse(code);
    const birthDate = [cf_data.year,cf_data.month,cf_data.day].join('-')
    const years = moment().diff(moment(birthDate,'YYYY-MM-DD'), 'years')

    return years >= 18
}

export const parse_decimal = (string, length = 2) => {
    const [integer, decimals] = string.split(",");
    if (decimals) {
        return String(
            `${integer},${
        decimals.length < length
          ? `${decimals}${"0".repeat(length - decimals.length)}`
          : decimals
      }`
        );
    } else {
        return String(`${integer},${"0".repeat(length)}`);
    }
};