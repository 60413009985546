import loadData from "@/dataService";
const config = loadData("config.json");
const enabled = config.tracking.yt ? config.tracking.yt.enabled : false;

function younited(userData) {
    if (!enabled) {
        return;
    }

    var eref = userData.orderID;
    var eamount = 1; //userData.importo;
    var se = document.createElement("script");
    se.type = "text/javascript";
    se.async = true;
    se.src = '//yoc.younited-credit.com/tpordertrigger/pret-dunion-it/Cofidis/' + Math.round(Math.random()*2147483647)
    var ses = document.getElementsByTagName("script")[0];

    if (process.env.NODE_ENV === "development") {
        console.log("Mock younited tracking", se.src, eref, eamount)
    } 
    else { 
        ses.parentNode.insertBefore(se, ses);
    }
}

export default younited;