import loadData from "@/dataService.js";
import { extractFiscalCode } from "@/utils";
import _ from "lodash";

const city = loadData("city.json");
const states = loadData("states.json");
const config = loadData("config.json");

export const dati_anag = (state) => {
	let payload = {
        EMail: state.userData.email,
        Phone: state.userData.cellphone.substring(3).replace(/ +/g, ""),
        Nome: state.userData.name,
        Cognome: state.userData.surname,
        CF: state.userData.fiscal_code,
        Importo: state.userData.importo,
        Rate: state.userData.rate,
        Permesso: 1        
    }
    // Add mapped url vars to add querystring param to payload    
    if(config.urlVars && config.urlVars.includes(process.env.NODE_ENV)) {
        let urlVars = JSON.parse(JSON.stringify(state.urlVars))        
        // add ignore vars if set by config
        if(config.ignoreVars) config.ignoreVars.map(prop => { delete urlVars[prop] })
        payload.query_string = urlVars
    }
    // add locale if set by theme config
    if(config.locales) {
        payload.Lang = state.lang
    }

    return payload
}

export const dati_pers_1 = (state) => {
	var cf = extractFiscalCode(state.userData.fiscal_code);
	var cities = city[cf.birthplaceProvincia];
	return {
        Sex: cf.gender,
        Cittadinanza: state.userData.citizenship,
        Permesso_Dal: state.userData.stay_from,
        Permesso_End: state.userData.stay_to,
        Stato_Civile: parseInt(state.userData.marital_status) - 1,
        Birthday_Day: cf.day,
        Birthday_Month: cf.month,
        Birthday_Year: cf.year,
        Birthday_State: parseInt(_.invert(states)[cf.country]) - 1,
        Birthday_Prov: cf.birthplaceProvincia,
        Birthday_City: parseInt(_.invert(cities)[cf.city])
    }
}

export const dati_pers_2 = (state) => {
	return {
        Abitazione: parseInt(state.userData.dwelling_type) - 1,
        Affitto: state.userData.monthly_rent ?
            state.userData.monthly_rent.replace(/€ /g, "").replace(/\./g, "") : null
    }
}

export const dati_pers_3 = (state) => {
	var prv = /\(([^)]+)\)/.exec(state.userData.address_city);
	var prv_dom = /\(([^)]+)\)/.exec(state.userData.address_city2);
	var cap = '0'.repeat(5 - state.userData.address_cap.toString().length) + state.userData.address_cap.toString()
    var cap_dom = state.userData.address_cap2 ?
        '0'.repeat(5 - state.userData.address_cap2.toString().length) + state.userData.address_cap2.toString() : null
    
    return {
        Indirizzo: state.userData.address,
        N: state.userData.address_number,
        CAP: cap,
        Comune: state.userData.address_city,
        Prov: prv ? prv[1] : null,
        City: state.userData.address_city.slice(0, -5),
        Abitazione_Dal: state.userData.resident_since,
        Domicilio: parseInt(state.userData.same_addresses) - 1,
        Indirizzo_Dom: state.userData.address2,
        N_Dom: state.userData.address_number2,
        CAP_Dom: cap_dom,
        Comune_Dom: state.userData.address_city2,
        City_Dom: state.userData.address_city2.slice(0, -5),
        Prov_Dom: prv_dom ? prv_dom[1] : null
    }
}
export const dati_pers_4 = (state) => {
	var prv_doc = /\(([^)]+)\)/.exec(state.userData.document_city);
    var releaseDate = state.userData.document_release ? 
           state.userData.document_release.split('/') :
           [0, 0, 0]
    var expireDate = state.userData.document_expire.split('/')
    return {
        Doc_Type: state.userData.identity_type,
        Doc_Number: state.userData.document_number.replace(/_/g, ""),
        Doc_Comune: state.userData.document_city,
        Doc_Data_Day: releaseDate[0],
        Doc_Data_Month: releaseDate[1],
        Doc_Data_Year: releaseDate[2],
        Doc_Data_End_Day: expireDate[0],
        Doc_Data_End_Month: expireDate[1],
        Doc_Data_End_Year: expireDate[2],
        Doc_City: state.userData.document_city.slice(0, -5),
        Doc_Prov: prv_doc ? prv_doc[1] : null
    }
}

export const dati_lav = (state) => {
	let payload = {
	    Attivita: parseInt(state.userData.employment_type),
	    Settore: parseInt(state.userData.employment_sector),
	    Occupazione: parseInt(state.userData.occupation),
	    Contratto: parseInt(state.userData.hiring_type),
	    Mensilita: parseInt(state.userData.monthly_payment),
	    Reddito: state.userData.monthly_income ?
	        state.userData.monthly_income
	        .replace(/€ /g, "")
	        .replace(/\./g, "") : null,
	    Occupato_Dal: state.userData.employed_since,
	    Occupato_Al: state.userData.employed_to,
	    Famigliari: parseInt(state.userData.family_member_count) - 1,
	    Famigliari_Reddito: parseInt(state.userData.employed_family_member_count) - 1
	};

	// Legacy adjustments
	if ([1, 9, 11].includes(payload.Attivita)) {
	    payload.Mensilita = 2;
	    payload.Contratto = 2;
	}
	if ([3].includes(payload.Attivita)) {
	    payload.Mensilita = 2;
	    payload.Contratto = 1;
	}
	if ([4, 5, 10].includes(payload.Attivita)) {
	    payload.Mensilita = 2;
	    payload.Reddito = 1;
	    payload.Contratto = null;
	}
	if ([6, 7].includes(payload.Attivita)) {
	    payload.Contratto = 2;
	}
	// reduce values to match cms options
	payload.Attivita = payload.Attivita - 1;
	payload.Settore = payload.Settore - 1;
	payload.Occupazione = payload.Occupazione - 1;
	payload.Mensilita = payload.Mensilita - 1;
	if (payload.Contratto) payload.Contratto = payload.Contratto - 1;

	// Latest Legacy adjustments
	if (payload.Attivita >= 3 && payload.Attivita < 10) {
	    payload.Settore = 0;
	    payload.Occupazione = payload.Attivita - 3;
	    payload.Attivita = 3;
	}
	if (payload.Attivita == 10) {
	    payload.Occupazione = payload.Settore;
	    payload.Settore = 5;
	    payload.Attivita = 0;
	}
	if (payload.Attivita == 0 && payload.Settore == 9) {
	    payload.Settore = 5;
	}
	if (
	    payload.Attivita == 0 &&
	    payload.Settore == 8 &&
	    payload.Occupazione == 2
	) {
	    payload.Settore = 5;
	}

	return payload
}

export const assicurazione = (state) => {
	return {
        assicurazione_pack: state.userData.insurance_pack
    }
}

export const assicurazione_consent = (state) => {
	return {
        assicurazione_pack: state.userData.insurance_pack
    }
}

export const assicurazione_warning = (state) => {
	return {
        assicurazione_pack: state.userData.insurance_pack
    }
}

export const dati_lav_2 = (state) => {
	var prv_emp = /\(([^)]+)\)/.exec(state.userData.employer_city);
    var cap_emp = '0'.repeat(5 - state.userData.employer_postal_code.toString().length) + state.userData.employer_postal_code.toString()
    return {
        Datore: state.userData.employer_name,
        Datore_Indirizzo: state.userData.employer_address,
        Datore_N: state.userData.employer_street_number,
        Datore_CAP: cap_emp,
        Datore_Comune: state.userData.employer_city,
        Datore_City: state.userData.employer_city ?
            state.userData.employer_city.slice(0, -5) : null,
        Datore_Prov: prv_emp ? prv_emp[1] : null,
        Datore_Phone: state.userData.employer_phone ?
            state.userData.employer_phone.substring(3).replace(/ +/g, "") : null
    }
}

export const dati_paga = (state) => {
	var iban = state.userData.iban.replace(/ +/g, "");
    return {
        Scadenza_Day: parseInt(state.userData.day_in_month) - 1,
        Iban: state.userData.iban,
        Iban_Paese: iban.slice(0, 2),
        Iban_CinEu: iban.slice(2, 4),
        Iban_Cin: iban.slice(4, 5),
        Iban_Abi: iban.slice(5, 10),
        Iban_Cab: iban.slice(10, 15),
        Iban_Cc: iban.slice(15, 27)
    }
}
export const dati_privacy = (state) => {
	let payload = {
        privacy1: +state.userData.privacy1,
        privacy2: +state.userData.privacy2,
        privacy3: +state.userData.privacy4,
        privacy4: +state.userData.privacy3,
        Politico: +state.userData.politics,
        OldClients: false
    };
    // add insurane data
    if (state.userData.insurance_available) {
        payload.assicurazione = +state.userData.insurance,
        payload.assicurazione_pack = state.userData.insurance_pack
    }

    return payload
}

export default { 
    dati_anag, 
    dati_pers_1, 
    dati_pers_2, 
    dati_pers_3, 
    dati_pers_4, 
    dati_lav, 
    assicurazione, 
    assicurazione_consent,
    assicurazione_warning,
    dati_lav_2, 
    dati_paga, 
    dati_privacy 
}