<template>
    <div class="cursor-pointer rounded-lg" :class="wrapperClass" @click="$emit('change', value)">
        <div :class="{ 'selected': value === modelValue }" class="calendar-icon flex items-center justify-center">
            <span class="block text-xl font-semibold mt-2">{{ label }}</span>
        </div>
    </div>
</template>
<script>
export default {
    model: {
        prop: "modelValue",
        event: "change"
    },
    props: {
        modelValue: {
            default: ""
        },
        value: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        hasError: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        wrapperClass() {
            return {
                "text-red border-red": this.hasError
            };
        }
    }
};
</script>