import Vue from 'vue';
import ga from "@/tracking/ga";
//import gtag from "@/tracking/gtag";
import gtm from "@/tracking/gtm";
import fbq from "@/tracking/fbq";
import adf from "@/tracking/adf";
import cxmtrxq from "@/tracking/cxmtrxq";
import quantcast from "@/tracking/quantcast";
import younited from "@/tracking/younited";

/** Set trackings */
Vue.prototype.$ga = ga;
//Vue.prototype.$gtag = gtag;
Vue.prototype.$gtm = gtm;
Vue.prototype.$fbq = fbq;
Vue.prototype.$adf = adf;
Vue.prototype.$cxmtrxq = cxmtrxq;
Vue.prototype.$quantcast = quantcast;
Vue.prototype.$younited = younited;
