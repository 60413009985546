<template>
    <div v-if="percentage" class="bg-form h-5 text-sm mx-6 lg:mx-12 mt-6 rounded-full">
        <div class="h-5 text-right bg-yellow text-black font-semibold pr-3 rounded-full" :style="{ width: percentage + '%' }">
            {{ percentage }}%
        </div>
    </div>
</template>
<script>
export default {
    props: {
        percentage: {
            type: Number,
            default: null
        }
    },
    computed: {        
        visible() {
            return !!this.percentage;
        }
    }
};
</script>