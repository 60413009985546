import loadData from "@/dataService";
const config = loadData("config.json");

class QCT {
    constructor(config) {
        this.enabled = config.enabled ? config.enabled : false;
        this.track_id = config.id ? config.id : -1;
        if (this.enabled) {
            this.initalize();
        }
    }
    initalize() {
        (function() {
            var s = document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
            s.onload = function() { window._qevents.push({qacct:this.track_id}) }
            var x = document.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
        })();
    }

    event(title, data = {}) {        
        if(!this.enabled) return;
        const _data =  {qacct: this.track_id, labels: title, event:"refresh"}
        
        if (process.env.NODE_ENV === "development") {
            console.log("Mock quantcast tracking", title, data);
        } 
        else {            
            window._qevents.push({..._data, ...data});
        }
    }
}
export default new QCT(config.tracking.qct ?? {});