const requireData = require.context("@", true, /data\/\w+\.json$/);

const getJson = (filename, theme) => {
    const path = `./views/themes/${theme}/data/${filename}`;
    const fallbackPath = `./data/${filename}`;
    
    try {
        return requireData(path);
    } catch (e) {
        try {
            return requireData(fallbackPath);
        } catch (e) {
            console.log("file not found :", filename);
            return undefined;
        }
    }
}

export default (filename) => {
    let theme = window.activeTheme || process.env.VUE_APP_THEME || "prestito";

    // manage redirects if any
    const config = getJson("config.json", theme)
    // enable redirects once (window.baseUrl not yet set)
    if(config.redirects && !window.baseUrl)
        config.redirects.map(obj => { 
            const paths = window.location.pathname.split('/')            
            const urlParts = window.location.href.split('?')
            // if path match given redirect and is enabled for active env            
            if (obj.env.includes(process.env.NODE_ENV) && paths.length > 1 && obj.from.replace(/\//g,'') === paths[1].toLowerCase()) {                  
                console.log(`redirect to ${obj.to}`)                
                window.baseUrl = obj.to
                // add original request url params if redirect does not contains its own
                if(!obj.to.match(/\?./) && urlParts[1]) window.baseUrl = window.baseUrl + '?' + urlParts[1]            
                // switch theme if set
                if(obj.theme) {
                  window.activeTheme = obj.theme               
                  theme = obj.theme
                }
            }
        })

    
    return getJson(filename, theme)
};