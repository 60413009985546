<template>
    <li class="proposal-limited open text-base sm:text-lg lg:text-xl flex flex-wrap border border-yellow border-t-0 lg:border-t lg:flex-no-wrap w-full text-gray-700 rounded-3xl lg:rounded-full lg:overflow-hidden mb-8 mt-2 lg:mb-0 lg:mt-0 bg-white" :key="`pl_${model.id}`" v-if="active">        
        <div class="lg:w-1/3 bg-yellow w-full flex items-center lg:justify-between px-2 rounded-t-3xl lg:rounded-t-lg lg:rounded-tr-none lg:border-r">    
            <span class="whitespace-no-wrap ml-2 lg:mr-0 ">{{ $t("Importo di") }}</span>
            <strong class="mr-auto lg:mr-0 p-2 whitespace-no-wrap">{{ model.imp_finanz_text }}</strong>
            <a class="sm:text-base underline mr-4" target="_blank" :href="secciUrl">SECCI</a>
        </div>
        <div class="lg:w-1/2 w-full flex mt-0 items-center justify-center p-2">
            <p v-if="text" v-html="text">
            <p v-else>
                <strong>{{ model.imp_rata }}</strong> € {{ $t("per") }}
                <strong>{{ model.rate }}</strong> {{ $t("mesi") }} {{ $t("TAEG") }}
                <strong>{{ model.taeg }}</strong>%
            </p>
        </div>
        <div class="w-full lg:w-1/3 -mb-6  text-center lg:text-right lg:m-1">
            <button class="bg-yellow rounded-full p-2 px-16 text-base font-bold sm:mt-0.5 sm:mr-0.5 shadow-xl lg:shadow-none hover:bg-yellow-4" @click="onSubmit">
                {{ $t("CONTINUA") }}
            </button>
        </div>
    </li>
    <li class="proposal-limited close text-xs ipp:text-sm sm:text-lg lg:text-xl flex flex-wrap items-center bg-white lg:flex-no-wrap w-full text-gray-700 rounded-full border border-border lg:overflow-hidden mb-2" :key="`pl_${model.id}`" v-else @click="$emit('selected', model.imp_finanz)">
        <div class="p-1 lg:flex-initial hidden lg:block">
            <strong class="xl:ml-10 0 p-2 whitespace-no-wrap">{{ model.imp_finanz_text }}</strong>
        </div>
        <div class="flex-1 lg:flex-initial pr-3 justify-start self-stretch lg:border-r flex items-center lg:justify-center lg:w-auto lg:ml-auto hidden lg:flex">
            <a class="sm:text-base underline mr-4" target="_blank" :href="secciUrl">SECCI</a>
        </div>
        <div class="btn-wrapper flex-1 lg:flex-initial text-right lg:w-1/4 lg:order-last hidden lg:block"> 
            <button class="bg-yellow rounded-full p-2 px-16 text-base font-bold lg:mr-0.5 shadow-xl lg:shadow-none hover:bg-yellow-4" @click="onSubmit()">
                {{ $t("CONTINUA") }}
            </button>
        </div>
        <div class="w-auto py-2 pl-2 pr-1 lg:flex-initial lg:w-1/2 lg:text-center">
            <p>
                <strong class="ml-2 lg:ml-10 lg:hidden">{{ model.imp_finanz_text }}</strong>
                <span class="lg:hidden ml-1 sm:ml-4 mr-1">rata</span>
                <span><strong>{{ model.imp_rata }}</strong> € {{ $t("per") }}
                <strong>{{ model.rate }}</strong> {{ $t("mesi") }} {{ $t("TAEG") }}
                <strong>{{ model.taeg }}</strong>%</span>
            </p>
        </div>
    </li>
</template>
<script>
export default {
    props: {
        importo: {
            type: Number,
            required: true
        },
        text: {
            type: String,
            required: false
        },
        active: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    computed: {
        secciUrl() {
            return `${this.baseUrl}${this.model.imp_finanz}_${this.model.rate}.pdf`
        },
        baseUrl() {
            return this.$store.state.secciUrl
        },
        model() {
            return this.$store.getters.rateOffers({ importo: this.importo })[0];
        }
    },
    methods: {
        onSubmit() {
            this.$store.commit("setLimitedCredit", this.model.imp_finanz)
            this.$store.commit("setChoosenRata", this.model.rate)            
            this.$store.dispatch("event", { name: "ProposalLimitedSubmit", source: this })
            this.$emit('rataClick', this.importo, this.model.rate)            
            this.$store.dispatch("redirect", { step: "proposal_limited", route: "RataUpgrade" })
        }
    }
};
</script>