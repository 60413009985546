import Vue from 'vue';

import SlideUpDown from 'vue-slide-up-down'
import Autocomplete from "@/components/Autocomplete";

const activeTheme = window.activeTheme || process.env.VUE_APP_THEME || "prestito";

/** Set components */
Vue.component("SlideUpDown", SlideUpDown);
Vue.component("Autocomplete", Autocomplete);
Vue.component("Footer", require(`./views/themes/${activeTheme}/Footer.vue`).default);

Vue.component("FormHeader", require("@/components/FormHeader.vue").default);
Vue.component("FormCard", require("@/components/FormCard.vue").default);
