<template>
	<div class="label-floating" :class="{ 'border-red': error,'border-gray-700': focusInput == name }" >                 
	    <input @focus="setFocusInput(name)" @blur="unsetFocusInput()" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" v-mask="mask" :type="type" :placeholder="$t(label)" :disabled="disabled" :class="className" class="form-input"  autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
	    <label>{{ $t(label) }}</label>
	</div>
</template>
<script>
export default {
	props: {
        name: {
        	type: String,
            required: true
        },
        value: {
        	required: true
        },
        type: {
            type: String,
            required: false,
            default: 'input'
        },
        mask: {
            type: Object,
            required: false
        },
        className: {
            type: String,
            required: false
        },
        label: {
            type: String,
            required: true
        },
        error: {
        	type: Boolean,
        	required: false,
        	default: false
        }
        ,
        disabled: {
        	type: Boolean,
        	required: false,
        	default: false
        }
    }
}
</script>