import loadData from "@/dataService";
const config = loadData("config.json");
const gevents = loadData("gevents.json");

class GTM {
    constructor(config) {
        this.enabled = config.enabled ? config.enabled : false;
        this.id = config.id ? config.id : -1;     

        if(this.enabled) this.initalize()        
    }
    initalize() {        
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementById('gconfig'),
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f.nextSibling);
        })(window,document,'script','dataLayer', this.id);        
    }
    dispatch(event, data) {
        if (this.enabled) {
            const e = gevents[event]
            if (e === undefined) throw `Event ${event} not defined in gevents.json`
            data = data || {}
            if (process.env.NODE_ENV === "development") {
                console.log("Mock GTM tracking", event, { ...{title: e.title}, ...e.data, ...data });
            } else {
                window.gtag('event', e.title, { ...e.data, ...data })
            }
        }
    }
}
export default new GTM(config.tracking.gtm ?? {});