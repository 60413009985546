<template>
    <FormCard @submit="onSubmit" title="Protezione del pagamento">
        <p class="text-red font-bold">
            Non perdere questa occasione! Difendi te e la tua famiglia dalle
            circostanze impreviste della vita.
        </p>
        <ul class="ml-4 mb-4 mt-2">
            <li>
                <strong class="text-red">- Assicura il rimborso del tuo prestito.
                    Rimani tranquillo contro incertezze future.</strong>
            </li>
            <li>
                <strong class="text-red">- Altre tipologie di assicurazioni potrebbero non coprirti nel caso di
                    problemi nella restituzione del prestito.</strong>
            </li>
        </ul>
        <p>
            <strong>La soluzione che ti proponiamo</strong>, con soli {{ getInsurancaRate }} euro di
            premio mensile in più sulla rata, <strong>copre</strong>:
        </p>
        <ul class="list-disc ml-4 mb-2 mt-2">
            <li>
                <b>Decesso</b> - in caso di decesso, l’assicurazione corrisponde una
                somma pari al capitale residuo del finanziamento alla data del decesso.
            </li>
            <li>
                <b>Perdita Totale e Irreversibile di Autonomia</b> – in caso di
                invalidità da infortunio o malattia, l’assicurazione corrisponde una
                somma pari al capitale residuo del finanziamento alla data di
                riconoscimento dell'invalidità.
            </li>
            <template v-if="insurancePackage == 'G'">
                <li>
                    <b>Perdita Involontaria di Impiego</b> - in caso di perdita del
                    lavoro, l’assicurazione corrisponde 9 mensilità alla data del
                    licenziamento.
                </li>
                <li>
                    <b>Inabilità Temporanea Totale al lavoro</b> - in caso di malattia e
                    impossibilità lavorativa, l’assicurazione rimborsa le residue
                    mensilità del finanziamento alla data del sinistro.
                </li>
            </template>
        </ul>
        <p class="my-4">
            Condizioni del contratto di polizza contenute nel
            <b>set informativo - in particolare presta attenzione alle limitazioni,
                alle franchigie, alle esclusioni e ai periodi carenza</b>
        </p>
        <div slot="link" class="text-center text-gray-700 text-sm mt-4 mb-8">
            <a href="#" @click.prevent="nextStep" class="underline">Procedi senza proteggerti</a>
        </div>
    </FormCard>
</template>
<script>
import { mapGetters } from "vuex";
import { mapUserData } from "@/utils";

export default {
    mounted() {
        this.$ga.pageview()
        this.$store.dispatch("event", { name: "LoadProtectionWarningLoaded" });
        this.$store.dispatch("sendData", { step: "assicurazione_warning" })
    },
    computed: {
        ...mapGetters(["insurancePackage", "insuranceRate"]),
        ...mapUserData(["employment_type", "insurance"]),
        getInsurancaRate() {
            return this.insuranceRate.replace('.', ',')
        }
    },
    methods: {
        onSubmit() {            
            this.$store.dispatch("event", { name: "LoanProtectionWarningSubmit" })
            this.$store.dispatch("redirect", { step: "insurance_warning", route: "LoanProtectionConsent" });
        },
        nextStep() {
            this.$store.commit("resetInsuranceConsent")
            
            if ([1, 11, 2, 3].includes(this.employment_type)) {
                this.$store.dispatch("redirect", { step: "insurance_next_step", route: "EmployerData" });
            } else {
                this.$store.dispatch("redirect", { step: "insurance_next_step", route: "PaymentDetails" });
            }
        }
    }
};
</script>