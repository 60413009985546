<template>
    <FormCard @submit="onSubmit" title="Dati personali">
        <div class="w-full sm:pr-4 sm:w-1/2 mb-8">
            <FloatingLabelSelect :name="`dwelling_type`" v-model="dwelling_type" :items="dwelling_types" :label="`Tipologia di abitazione`" :error="$v.dwelling_type.$error" :disableZeroValue="true"/>
            <div class="text-red">
                <small v-if="$v.dwelling_type.$error && !$v.dwelling_type.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div v-if="dwelling_type === 1" class="w-full sm:w-1/2 mb-4 sm:mb-0">
            <FloatingLabelInput :name="`monthly_rent`" v-model="monthly_rent" :mask="currencyMask" :label="`Affitto mensile`" :error="$v.monthly_rent.$error"/>      
            <div class="text-red">
                <small v-if="$v.monthly_rent.$error && !$v.monthly_rent.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
    </FormCard>
</template>
<script>
import FloatingLabelSelect from "@/components/FloatingLabelSelect";    
import FloatingLabelInput from "@/components/FloatingLabelInput";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapUserData } from "@/utils";
import { currencyMask } from "@/masks";
export default {
    components: { FloatingLabelSelect, FloatingLabelInput },
    mounted() {
        this.$ga.pageview()
    },
    data() {
        return {
            currencyMask,
            dwelling_types: [
                {key: null, value: "Tipologia di abitazione"},
                {key: 1, value: "IN AFFITTO"},
                {key: 2, value: "CON GENITORI, PARENTI"},
                {key: 5, value: "DI PROPRIETÀ"}
            ]
        };
    },
    computed: mapUserData(["dwelling_type", "monthly_rent"]),
    validations: {
        dwelling_type: {
            required
        },
        monthly_rent: {
            required: requiredIf(function() {
                return this.dwelling_type === 1;
            })
        }
    },
    watch: {
        dwelling_type() {
            this.monthly_rent = null
            this.$v.$reset()
        }
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("event", { name: "PersonalData2Submit" });
                this.$store.dispatch("sendData", { step: "dati_pers_2" });
                this.$store.dispatch("redirect", { step: "dati_pers_2", route: "DeliveryData" });
            }
        }
    }
};
</script>