import numeral from "numeral";
import _ from "lodash";
import { parse_decimal } from "@/utils";
import { extractFiscalCode } from "@/utils";
import moment from "moment";
import loadData from "@/dataService";

const config = loadData("config.json");

numeral.register("locale", "it", {
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  currency: {
    symbol: "€"
  }
});

numeral.locale("it");

export default {
  
  uniquePrices: state => {
    const ret = state.prices.reduce((red, val) => {
      if (!(val.imp_finanz in red))
        red[val.imp_finanz] = numeral(parseInt(val.imp_finanz)).format("$ 0,0");
      return red;
    }, {});
    return Object.keys(ret)
      .reverse()
      .map(key => {
        return { value: parseInt(key), name: ret[key] };
      });
  },

  insuranceAvailable: (state, getters) => {
    if (!config.insurance) return false;
    if (!getters.fiscalCodeExtracted) return false;
    if (!state.userData.rate) return false;
    if ([0, 5, 9].indexOf(state.userData.employment_type) !== -1) return false;
    const birthYear = getters.fiscalCodeExtracted.year;
    const age = moment().year() - birthYear;
    return age + 1 + state.userData.rate / 12 <= 65;
  },

  insuranceRate: (state, getters) => {
    let multiplier = 0.00169
    if (getters.insurancePackage == "D") multiplier = 0.00048
    if (getters.insurancePackage == "E") multiplier = 0.000613
    
    // ceil 2 decimal
    return (Math.ceil(parseFloat(state.userData.importo) * multiplier * 10) / 10).toFixed(2)
  },

  insurancePackage: state => {
    if (state.userData.rate <= 24) return "D";

    if (state.userData.employment_type == 2) {
      if (state.userData.employment_sector == 4) {
        if (
          state.userData.occupation !== 2 &&
          state.userData.hiring_type == 2
        ) {
          return "G";
        }
      }
      if (
        [1, 2, 3, 5, 7, 10].indexOf(state.userData.employment_sector) !== -1
      ) {
        if (
          (state.userData.occupation !== 1 &&
            state.userData.hiring_type == 2) ||
          state.userData.occupation == 2
        ) {
          return "G";
        }
      }
      if (state.userData.employment_sector == 8) {
        if (
          state.userData.occupation == 4 ||
          (state.userData.hiring_type == 2 && state.userData.occupation !== 1)
        ) {
          return "G";
        }
      }
    }
    return "E";
  },

  rateOffers: state => ({ importo, justone = true }) => {
    {
      const res = _.orderBy(state.prices, ["rate"], ["desc"]);
      const ret = _.reduce(
        res,
        (red, val) => {
          if (val.imp_finanz == importo) {
            if (justone === true && red.length > 0) {
              return red;
            }
            val.imp_rata = parse_decimal(
              String(val.imp_rata).replace(".", ","),
              2
            );
            val.taeg = parse_decimal(String(val.taeg).replace(".", ","), 2);
            val.imp_finanz_text = numeral(parseInt(val.imp_finanz)).format(
              "0,0 $"
            );
            red.push(val);
          }
          return red;
        },
        []
      );
      if (justone === false) ret.shift()
      
      return ret;
    }
  },

  fiscalCodeExtracted: state => {
    return extractFiscalCode(state.userData.fiscal_code);
  }
};
