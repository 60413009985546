<template>
    <form class="" action="next" method="POST" data-model="importo" @submit.prevent="onSubmit">
        <div class="max-w-md mx-auto">
            <div>
                <select name="Importo" class="home-select" v-model="choosenCredit" :class="{ 'border-red': $v.choosenCredit.$error }">
                    <option value="0" disabled>{{ $t("Scegli l'importo") }}</option>
                    <option v-for="price in uniquePrices" :key="price.value" :value="price.value">{{ price.name }}</option>
                </select>
                <div class="h-6 w-full text-red text-left" v-if="$v.choosenCredit.$error && !$v.choosenCredit.greaterThanZero">
                    <small class="text-xs font-medium">{{ $t("Campo obbligatorio") }}</small>
                </div>
            </div>
            <button class="home-submit" type="submit">
                <span class="block font-bold text-xl leading-5">{{ $t("Continua") }}</span>
                <span class="block leading-5 text-base">{{ $t("esito immediato") }}</span>
            </button>
        </div>
    </form>
</template>
<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import loadData from "@/dataService.js";
const defaultCredit = loadData("price.json").default_credit;

const greaterThanZero = (value) => value > 0

export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["uniquePrices"]),
        choosenCredit: {
            get() {
                return this.$store.state.choosenCredit;
            },
            set(val) {
                this.$store.commit("setChoosenCredit", val);
            }
        }
    },
    mounted() {
        this.choosenCredit = defaultCredit;
    },
    validations: {
        choosenCredit: {
            required,
            greaterThanZero
        }
    },
    methods: {
        onSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {                
                this.$store.dispatch("event", { name: "FormImportoSubmit" })
                this.$store.dispatch("redirect", { step: "importo", route: "Rata" });            
            }
        }
    }
};
</script>