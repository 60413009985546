import loadData from "@/dataService";
const config = loadData("config.json");
const fbevents = loadData("fbevents.json");

class FBQ {
    constructor(config) {
        this.enabled = config.enabled ? config.enabled : false;
        this.track_id = config.id ? config.id : -1;
        if (this.enabled) {
            this.initalize();
        }
    }
    initalize() {
        window.fbq('init', this.track_id);
        window.fbq('track', 'PageView');     
    }
    event(name, title, data) { 
        if (!this.enabled) return;
        
        // check if event is defined     
        if(!this.enabled || !fbevents[title]) return;
        
        if (process.env.NODE_ENV === "development") {
            console.log("Mock fbq tracking", name, fbevents[title], data ?? {});
        } 
        else {            
            window.fbq(name, fbevents[title], data ?? {})
        }
    }
}
export default new FBQ(config.tracking.fbq ?? {});