<template>
    <PrestitoPrivacy />
</template>
<script>
// @ is an alias to /src
import PrestitoPrivacy from "@/views/themes/prestito/Privacy";

export default {
    name: "Privacy",
    components: {
        PrestitoPrivacy
    }
};
</script>