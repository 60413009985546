import Vue from "vue";
import Vuelidate from "vuelidate";
import VueInputMask from "vue-inputmask";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";
import i18n from './i18n'
import PortalVue from 'portal-vue'

import 'animate.css';

import './bootstrap.js';
import './filters.js';
import './directives.js';
import './trackings.js';
import './components.js';
import './constants.js';
import './mixin.js';

/** Set modules */
Vue.use(Vuelidate)
Vue.use(VueAxios, axios)
Vue.use(VueInputMask.default)
Vue.use(PortalVue)
Vue.config.productionTip = false

/** Create Bus to communicate outside Vue instance */
window.event = new Vue()

/** Make App */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#content");
