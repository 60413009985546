import Cookies from "js-cookie";

class Append {
    dati_anag(payload, {state}) {
        const expireDays = 90
        // get querystring param
        const gclid = state.urlVars['gclid'] || Cookies.get("gclid")
        // add gclid to payload
        if(gclid) payload.gclid = gclid                    
        // store gclid in cookies if set in querystring
        if(state.urlVars['gclid']) Cookies.set("gclid", gclid, { expires: expireDays });
    }
}
export default new Append();