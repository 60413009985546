<template>
    <div class="w-full mb-4">
        <div class="flex flex-wrap sm:flex-nowrap">
            <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                <input type="radio" @change="v.$touch(); $emit('input', true);" :checked="value === true" class="form-radio" /><label>sì</label>
            </div>
            <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                <input type="radio" @change="v.$touch(); $emit('input', false);" :checked="value === false" class="form-radio" /><label>no</label>
            </div>
            <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2">
                <slot></slot>
            </div>
        </div>
        <div class="w-full mb-4 text-red">
            <div v-if="v.$error && !v.required">
                {{ $t("Campo obbligatorio") }}
            </div>
            <div v-if="v.$error && !v.mustBeTrue">
                {{ $t(mustBeTrueError) }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: null
        },
        v: {
            type: Object,
            required: true
        },
        mustBeTrueError: {
            type: String,
            default: "ATTENZIONE non sarà possibile procedere alla sottoscrizione della polizza."
        }
    }
};
</script>