<template>
    <FormCard @submit="onSubmit" title="Dati personali">
        <div class="w-full sm:w-1/2 mb-4 lg:w-1/4 hidden">
            <label class="text-xs">{{ $t("Data di nascita") }}</label>
            <div class="flex w-full">
                <div class="w-1/3 pr-1">
                    <input class="form-input" :value="birthdayDay" disabled />
                </div>
                <div class="w-1/3 pr-1">
                    <input class="form-input" :value="birthdayMonth" disabled />
                </div>
                <div class="w-1/3">
                    <input class="form-input" :value="birthdayYear" disabled />
                </div>
            </div>
            <div class="cont_fail fail_Birthday"></div>
        </div>
        <div class="w-full sm:w-1/2 sm:pl-4 mb-4 lg:w-1/4 lg:pr-4 hidden">
            <label class="text-xs">{{ $t("Stato di nascita") }}</label>
            <input type="text" class="form-input" :value="fiscalCodeExtracted.country" disabled />
        </div>
        <div class="w-full sm:w-1/6 sm:pr-4 mb-4 lg:w-1/12 hidden">
            <label class="text-xs">{{ $t("Provincia") }}</label>
            <input class="form-input" :value="fiscalCodeExtracted.birthplaceProvincia" disabled />
            <div class="cont_fail fail_Birthday_Prov"></div>
        </div>
        <div class="w-full sm:w-2/6 lg:w-1/6 hidden">
            <label class="text-xs">{{ $t("Comune") }}</label>
            <input type="text" class="form-input" :value="fiscalCodeExtracted.city" disabled />
        </div>
        <div class="w-full lg:w-1/3 lg:pr-4" :class="{'mb-4 sm:mb-0 sm:mt-6': showStayFrom}">
            <FloatingLabelSelect :name="`citizenship`" v-model="citizenship" :items="citizenshipOptions" :label="`Cittadinanza`" :disableZeroValue="true" :error="$v.citizenship.$error" :itemKey="`value`" :itemValue="`name`" :valueType="`String`"/>
            <div class="text-red">
                <small v-if="$v.citizenship.$error && !$v.citizenship.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div v-if="showStayFrom" class="w-full lg:w-1/3 mb-4 sm:mb-0">
            <label class="text-xs">{{ $t("Soggiorno dal") }}</label>
            <DateSelect :value="stay_from" :showDay="false" :inputClass="{ 'border-red': $v.stay_from.$error }" :startYear="2000" @change="updateStayFromValue" ref="stayFromComponent" />
            <div class="text-red">
                <small v-if="$v.stay_from.$error && !$v.stay_from.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="$v.stay_from.$error && !$v.stay_from.minValue">
                    {{ $t("Data non valida") }}
                </small>
                <small v-else-if="$v.stay_from.$error && !$v.stay_from.maxValue">
                    {{ $t("Data non valida") }}
                </small>
            </div>
        </div>
        <div class="w-full lg:w-1/3 lg:pl-4 lg:pr-4" :class="{'hidden': !showStayTo}">
            <label class="text-xs whitespace-no-wrap">{{ $t("Scadenza permesso di soggiorno") }}</label>
            <DateSelect :value="stay_to" :inputClass="{ 'border-red': $v.stay_to.$error }" :startYear="stayToStartYear" :endYear="stayToEndYear" @change="updateStayToValue" ref="stayToComponent" />
            <div class="text-red">
                <small v-if="$v.stay_to.$error && !$v.stay_to.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="$v.stay_to.$error && !$v.stay_to.minValue">
                    {{ $t("Data non valida") }}
                </small>
                <small v-else-if="$v.stay_to.$error && !$v.stay_to.maxValue">
                    {{ $t("Data non valida") }}
                </small>
            </div>
        </div>
        <template slot="after">
            <div class="marital-status grid grid-cols-3 md:grid-cols-6 pt-4 lg:max-w-3xl mx-auto">                
                <MaritalStatus v-model="marital_status" :label="$t('Celibe/Nubile')" :hasError="$v.marital_status.$error" :value="1"></MaritalStatus>
                <MaritalStatus v-model="marital_status" :hasError="$v.marital_status.$error" :label="$t('Divorziato/a')" :value="2"></MaritalStatus>
                <MaritalStatus v-model="marital_status" :hasError="$v.marital_status.$error" :label="$t('Coniugato/a')" :value="3"></MaritalStatus>
                <MaritalStatus v-model="marital_status" :label="$t('Convivente')" :hasError="$v.marital_status.$error" :value="4"></MaritalStatus>
                <MaritalStatus v-model="marital_status" :label="$t('Separato/a')" :hasError="$v.marital_status.$error" :value="5"></MaritalStatus>
                <MaritalStatus v-model="marital_status" :hasError="$v.marital_status.$error" :label="$t('Vedovo/a')" :value="6"></MaritalStatus>
            </div>
            <div class="text-red text-center mb-10">
                <small v-if="$v.marital_status.$error && !$v.marital_status.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </template>
    </FormCard>
</template>
<script>
import { mapGetters } from "vuex";
import DateSelect from "@/components/DateSelect";
import MaritalStatus from "@/components/MaritalStatus";
import moment from "moment";
import loadData from "@/dataService";
const citizenshipOptions = loadData("cittadinanza.json");
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapUserData } from "@/utils";
import { dateMask, shortDateMask } from "@/masks";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";

export default {
    components: { FloatingLabelSelect, MaritalStatus, DateSelect },
    mounted() {
        this.$ga.pageview()
    },
    data() {
        return {
            dateMask,
            DateSelect,
            shortDateMask,
            citizenshipOptions
        };
    },
    watch: {
        citizenship() {
            this.stay_from = null;
            this.stay_to = null;
            if (this.$refs.stayFromComponent) this.$refs.stayFromComponent.reset();
            if (this.$refs.stayToComponent) this.$refs.stayToComponent.reset();
            this.$v.$reset();
        }
    },
    validations: {
        citizenship: {
            required
        },
        marital_status: {
            required
        },
        stay_from: {
            required: requiredIf("showStayFrom"),
            minValue: (value, vm) => {
                return vm.showStayFrom ? moment(value, 'MM/YYYY') >= moment("01/2000", 'MM/YYYY') : true
            },
            maxValue: (value, vm) => {
                return vm.showStayFrom ? moment(value, 'MM/YYYY') <= moment() : true
            }
        },
        stay_to: {
            required: requiredIf("showStayTo"),
            minValue: (value, vm) => {
                return vm.showStayTo ? moment(value, 'DD/MM/YYYY') >= moment() : true
            },
            maxValue: (value, vm) => {
                return vm.showStayTo ? moment(value, 'DD/MM/YYYY') <= moment().add(10, "years") : true
            }
        }
    },
    computed: {
        ...mapGetters(["fiscalCodeExtracted"]),
        ...mapUserData(["stay_from", "stay_to", "marital_status", "citizenship"]),
        birthdayDay() {
            return this.fiscalCodeExtracted.day < 10 ?
                "0" + this.fiscalCodeExtracted.day :
                this.fiscalCodeExtracted.day;
        },
        birthdayMonth() {
            return this.fiscalCodeExtracted.month < 10 ?
                "0" + this.fiscalCodeExtracted.month :
                this.fiscalCodeExtracted.month;
        },
        birthdayYear() {
            return this.fiscalCodeExtracted.year;
        },
        showStayFrom() {
            return !!this.citizenshipOptions.find(
                item => item.value === this.citizenship
            ).pass;
        },
        showStayTo() {
            return this.citizenship == "BLS" || // Bielorussia
                   this.citizenship == "EXT" || // EXTRA-UE
                   this.citizenship == "REU" || // UK
                   this.citizenship == "RUS" || // RUSSIA
                   this.citizenship == "UKR" // UKRAINE
        },
        stayToStartYear() {
            return parseInt(moment().format('YYYY'))
        },
        stayToEndYear() {
            return parseInt(moment().add(10, "years").format('YYYY'))
        }
    },
    methods: {
        updateStayToValue(value) {
            this.stay_to = value
        },
        updateStayFromValue(value) {
            this.stay_from = value
        },
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("event", { name: "PersonalData1Submit" });
                this.$store.dispatch("sendData", { step: "dati_pers_1" });
                this.$store.dispatch("redirect", { step: "dati_pers_1", route: "PersonalData2" });                
            }
        }
    }
};
</script>