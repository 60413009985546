<template>
    <div id="content" :class="contentClass" class="sm:px-0 bg-form min-h-screen flex flex-col relative">
        <div class="logo-wrapper bg-white py-2">
            <div class="w-11/12 sm:max-w-7xl mx-auto flex flex-wrap items-center">
                <div class="flex-shrink">
                    <img :alt="$t('Cofidis Logo')" :title="$t('Cofidis Logo')" id="logo" class="h-14" :src="logoSrc" />
                </div>
                <div v-if="showSecureFormBadge" class="flex-grow text-right secure-form relative">
                    <div class="float-right cursor-pointer flex items-center justify-right"  @click="showSecureFormBadgeCloseIcon = !showSecureFormBadgeCloseIcon">
                        <img src="@/assets/images/secure-form.svg"/>
                        <span class="ml-2 font-semibold underline whitespace-nowrap text-sm">Form sicuro</span>
                    </div>
                    <div v-if="showSecureFormBadgeCloseIcon" class="absolute right-0 top-8 shadow-lg rounded-3xl p-4 bg-white text-left text-sm w-56 z-20">
                        <div class="w-full text-right">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="showSecureFormBadgeCloseIcon = false">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <p class="mt-2">I dati personali che condividi con Cofidis come parte della propria applicazione credito:</p>
                        <ul class="list-disc px-4 my-2 text-sm">
                            <li class="mt-2">Sono confinati in un database sicuro</li>
                            <li class="mt-2">Sono criptati utilizzando la tecnologia SSL</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <SlideUpDown v-if="showFlashMessage" :active="flashMessage !== null" class="w-full bg-green-500 px-4">
            <p class="text-center text-white font-semibold p-2" v-html="flashMessage" />
        </SlideUpDown>
        
        <div :class="containerClass">
            <Progress v-if="showProgressBar" :percentage="percentage"></Progress>       
            <router-view />
            <div v-if="showBackButton" class="text-center text-sm text-gray-700 mb-12 mt-4">
                <a href="#" @click.prevent="goBack()" class="back-btn underline">{{ $t("Torna indietro") }}</a>
            </div>
        </div>

        <portal-target name="sub-form">
          <!--
          This component can be located anywhere in your App.
          The slot content of the above portal component will be rendered here.
          -->
        </portal-target>
                
        <transition name="modal">
            <div v-if="showModal" class="bg-black bg-opacity-25 absolute inset-0 flex items-center justify-center z-10">
                <div class="modal-wrapper bg-white p-4 mx-4 text-center rounded-3xl mb-64 max-w-xl text-gray-700 shadow-xl">
                    <img class="block mx-auto sm:inline-block align-middle my-3" src="@/assets/images/alert-clock.svg" />
                    <h1 class="text-2xl sm:text-3xl font-semibold md:w-2/3 mx-auto">
                        {{ $t("Guarda che stai perdendo una grossa opportunità") }}
                    </h1>
                    <p class="my-6">{{ $t("Ti manca poco per terminare!") }}</p>
                    <div class="mb-4">
                        <button @click="closeModal" class="bg-yellow rounded-full p-2 px-16 font-semibold shadow-xl" type="button">{{ $t("OK") }}</button>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="error">
            <div v-if="showError" class="bg-black bg-opacity-25 absolute inset-0 flex items-center justify-center z-10">
                <div class="modal-wrapper bg-white p-4 mx-4 text-center rounded-3xl mb-64 max-w-xl text-gray-700 shadow-xl">
                    <h1 class="text-2xl sm:text-3xl font-semibold md:w-2/3 mx-auto">
                        {{ $t("Siamo spiacenti") }}
                    </h1>
                    <p class="my-6">{{ $t("Abbiamo rilevato un errore nella connessione con il server, per favore ricarica la pagina") }}</p>
                    <div class="mb-4">
                        <button @click="reload" class="bg-yellow rounded-full p-2 px-16 font-semibold shadow-xl" type="button">{{ $t("OK") }}</button>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="error">
            <div v-if="pendingOrder" class="bg-black bg-opacity-50 absolute inset-0 flex items-start justify-center z-10">
                <div class="modal-wrapper bg-white p-4 mx-4 text-center rounded-lg mt-8 max-w-xl text-gray-700">
                    <h1 class="text-2xl font-bold">
                        {{ $t("Impossibile procedere") }}
                    </h1>
                    <p class="my-6">{{ $t("Hai già inviato una richiesta di prestito: controlla la tua e-mail per conoscere l’esito.") }}<br>{{ $t("Se invece vuoi compilare una nuova richiesta, clicca il bottone qui sotto.") }}</p>
                    <div>
                        <button @click="reload" class="btn btn-primary" type="button">{{ $t("Nuova richiesta") }}</button>
                    </div>
                </div>
            </div>
        </transition>
        <Footer />
    </div>
</template>
<script>
// @ is an alias to /src
import Progress from "@/components/Progress.vue";
import $ from "jquery";

export default {
    components: {
        Progress
    },
    data() {
        return {
            showModal: false,
            showSecureFormBadgeCloseIcon: false,
            logoSrc: this.$store.state.logo            
        };
    },
    mounted() {
        $("#content")
            .stop()
            .animate({ opacity: 1 }, 500, "swing", function() {});

        window.addEventListener("mousemove", this.showModalHandler);        

        this.$ga.pageview()        
    },
    destroyed() {
        window.removeEventListener("mousemove", this.showModalHandler);
    },
    computed: {
        percentage() {
            if (this.$route.meta && this.$route.meta.percentage) {
                return parseInt(this.$route.meta.percentage)
            }
            return 0
        },
        flashMessage() {
            return this.$store.state.flashMessage
        },
        showError() {
            return this.$store.state.userData.hasError
        },
        pendingOrder() {
            return this.$store.state.userData.pendingOrder
        },
        showProgressBar() {            
            return (
                ["Homepage", "Rata", "RataUpgrade"].indexOf(this.$route.name) === -1
            );
        },        
        showSecureFormBadge() {
            return (
                ["Homepage", "Rata", "RataUpgrade", "RataPowerCredit","RataPowerCreditUpgrade"].indexOf(this.$route.name) === -1
            );
        },
        showFlashMessage() {
            return (
                ["Rata", "RataPowerCredit"].includes(this.$route.name)
            );
        },
        showBackButton() {
            return ! this.$route.meta.backHidden
        },
        contentClass() {
            let route = this.$route.name ? this.$route.name.toLowerCase() : ''
            let className = `theme-${this.$store.state.theme} ${route}`
            if(this.showModal || this.showError || this.pendingOrder) className += ' overflow-hidden h-screen'
            return className
        },
        containerClass() {
            let route = this.$route.name ? this.$route.name.toLowerCase() : ''
            if(route != 'homepage' && route != 'privacypolicy')
                return 'w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-4 mb-10 lg:my-10'
            else 
                return ''
        }
    },
    methods: {                
        showModalHandler(event) {
            this.$nextTick(() => {
                if (this.$route.meta.alert && process.env.NODE_ENV != "development") {
                    if (event.pageY < 10) {
                        this.showModal = true;
                    }
                }
            });
        },
        closeModal() {
            this.showModal = false;
            window.focus();
        },
        goBack() {            
            this.$store.dispatch("event", { name: "BackClick", model: this.$route.meta.model });
            this.$store.commit("unlogStep");
            this.$router.go(-1);
        },
        reload() {
            this.showError = false;
            this.$store.commit("resetState");
            if(this.$store.state.protected)
                this.$router.push({ name: "Login" })
            else
                this.$router.push({ name: "Homepage" })
        }
    }
};
</script>