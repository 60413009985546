<template>
    <div>        
        <div class="container mx-auto text-center p-5 pt-10">
            <h1 class="text-2xl sm:text-3xl font-bold text-gray-700 leading-tight inline-block lg:mr-5 mb-2">
                Attivalo gratis e scegli quanto vuoi
            </h1>
            <select name="Importo" class="home-select w-full lg:w-1/5 lg:inline-block" v-model="defaultCredit">
                <option value="2000">€ 2.000</option>
                <option value="1500">€ 1.500</option>
                <option value="1000">€ 1.000</option>
                <option value="600">€ 600</option>
                <option value="0">Solo attivazione</option>
            </select>
        </div>
        <Contatti class="mb-5" />
    </div>
</template>
<script>
// @ is an alias to /src
import Contatti from "@/views/forms/Contatti.vue";

export default {
    name: "ContattiPowerCredit",
    components: {
        Contatti
    },
    watch: {
        defaultCredit: function(val) {
            this.$store.commit("setChoosenCredit", val)
            this.$store.commit("upadateUserCreditData")
        }
    },
    data() {
        return {
            defaultCredit: 2000
        };
    },
    mounted() {
        this.$store.commit("setChoosenCredit", this.defaultCredit)
        this.$store.commit("upadateUserCreditData")
    }
};
</script>