<template>
    <section>
        <PrestitoTyp />
        <!-- Pixel impression -->
        <img src="http://yoc.younited-credit.com/tpview/pret-dunion-it/LeadCofidis/LeadCofidis/Cofidis/ThnkYou-Page/http://yoc.younited-credit.com/tpclick/pret-dunion-it" border="0" width="1" height="1" />
        <img v-if="smsCampaign" src="http://yoc.younited-credit.com/tpview/pret-dunion-it/LEAD_COFIDIS_SMS/LEAD_COFIDIS_SMS/Cofidis/SMS/http://yoc.younited-credit.com/tpclick/pret-dunion-it" border="0" width="1" height="1" />
        <img v-if="demCampaign" src="http://yoc.younited-credit.com/tpview/pret-dunion-it/LEAD_COFIDIS_DEM/LEAD_COFIDIS_DEM/Cofidis/DEM/http://yoc.younited-credit.com/tpclick/pret-dunion-it" border="0" width="1" height="1" />
    </section>
</template>
<script>
// @ is an alias to /src
import PrestitoTyp from "@/views/themes/prestito/Typ";

export default {
    name: "Typ",
    components: {
        PrestitoTyp
    },
    computed: {
        smsCampaign() {
            return this.$store.state.urlVars['source'] && this.$store.state.urlVars['source'] == 'SMSYC'
        },
        demCampaign() {
            return this.$store.state.urlVars['source'] && this.$store.state.urlVars['source'] == 'DEMYC'
        } 
    }
};
</script>