<template>
	<main></main>
</template>
<script>
export default {
    name: "Home",
    mounted() {
        // url protection        
        if ( this.$store.state.urlVars['allowed_referral'] ) {            
            const query = JSON.parse(JSON.stringify(this.$store.state.urlVars))            
            this.$router.push({ name: "ContattiPowerCredit", query })
            //this.$store.dispatch("redirect", { step: "home", route: "ContattiPowerCredit" });            
        }
        else {            
            window.location.replace("https://www.powercreditcofidis.it/");
        }
    }
}
</script>