import { v1 as uuidv1 } from "uuid";

export default function() {
  return {
    hasError: false,
    pendingOrder: false,
    
    orderID: uuidv1(),
    importo: 10000,
    rate: null,
    name: "TEST",
    email: "test@test.com",
    surname: "TEST",
    cellphone: "+39 333 1234567",
    fiscal_code: "TSTTST80A01F205J",
    stay_from: null,
    stay_to: null,
    marital_status: 1,
    financial_problems: false,
    citizenship: "ITA",

    dwelling_type: 1,
    monthly_rent: "€ 400",
    address: "TEST",
    address_number: "11/A",
    address_cap: "20133",
    address_multicap: [],
    address_city: "MILANO (MI)",
    resident_since: "01/1980",
    same_addresses: true,
    address2: "",
    address_number2: "",
    address_cap2: "",
    address_multicap2: [],
    address_city2: "",
    
    identity_type: 1,
    document_number: "AA1234567",
    document_city: "MILANO (MI)",
    document_expire: '01/01/2025',
    document_release: '01/01/2020',

    employment_type: 2,
    family_member_count: 1,
    employed_family_member_count: 1,
    employment_sector: 6,
    occupation: 1,
    monthly_payment: 4,
    monthly_income: "€ 1400",
    employed_since: "02/2015",
    employed_to: null,
    hiring_type: 2,

    employer_name: "TEST",
    employer_address: "TEST",
    employer_street_number: "3",
    employer_postal_code: "20133",
    employer_multicap: [],
    employer_city: "MILANO (MI)",
    employer_phone: "+39 333123456",

    insurance: false,
    insurance_amount: null,
    insurance_pack: null,
    insurance_available: false,    

    consent_main: null,
    consent1: null,
    consent2: null,
    consent3: null,
    consent4: null,
    consent5: null,
    consent6: null,
    consent7: null,

    day_in_month: 1,
    iban: "IT44C0300203280174225259217",

    privacy1: null,
    privacy2: null,
    privacy3: null,
    privacy4: null,
    politics: null
  };
}
