<template>
    <FormCard @submit="onSubmit" title="Consensi privacy" submit-text="INVIA RICHIESTA" >
        <div class="flex flex-wrap sm:flex-nowrap">
            <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                <input type="radio" v-model="privacy1" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
            </div>
            <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                <input type="radio" v-model="privacy1" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
            </div>
            <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('Lette e comprese l\'<a target=\'_blank\' href=\'https://www.cofidis.it/it/download/Informativa-Privacy-B2C_0123.pdf\' class=\'underline\'>Informativa privacy e il Modello Unico di Informativa</a>, acconsento al trattamento dei Dati Personali Particolari cd. sensibili funzionali o connessi all’esecuzione dei Servizi e acconsento alla comunicazione dei miei dati positivi ai SIC (Sistemi di Informazioni Creditizie), <strong>consapevole/i che, in mancanza del consenso, Cofidis non potrà dar corso alle operazioni o servizi richiesti</strong>. Puoi gestire i seguenti consensi facoltativi:')">
            </div>
        </div>
        <div class="w-full mb-4 text-red">
            <div v-if="$v.privacy1.$error && !$v.privacy1.required">
                {{ $t("Campo obbligatorio") }}
            </div>
            <div v-if="privacy1 === false">
                {{ $t("Non è possibile proseguire senza questo consenso.") }}
            </div>
        </div>
        <div class="flex flex-wrap sm:flex-nowrap">
            <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                <input type="radio" v-model="privacy2" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
            </div>
            <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                <input type="radio" v-model="privacy2" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
            </div>
            <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('acconsento al trattamento dei miei dati personali per finalità di marketing')">
            </div>
        </div>
        <div class="w-full mb-4 text-red">
            <div v-if="$v.privacy2.$error && !$v.privacy2.required">
                {{ $t("Campo obbligatorio") }}
            </div>
            <div v-if="privacy2 === false">
                {{ $t("Attenzione! Se scegli “no” non potrai partecipare alle nostre attività promozionali, a concorsi, né ricevere offerte di altri prodotti Cofidis.") }}
            </div>
        </div>
        <div class="flex flex-wrap sm:flex-nowrap">
            <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                <input type="radio" v-model="privacy3" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
            </div>
            <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                <input type="radio" v-model="privacy3" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
            </div>
            <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('acconsento al trattamento dei miei dati personali per finalità di profilazione delle mie preferenze, caratteristiche, abitudini o scelte di consumo al fine di ricevere offerte, promozioni ed informazioni che tengano conto dei miei interessi')">
            </div>
        </div>
        <div class="w-full mb-4 text-red">
            <div v-if="$v.privacy3.$error && !$v.privacy3.required">
                {{ $t("Campo obbligatorio") }}
            </div>
            <div v-if="privacy3 === false">
                {{ $t("Attenzione! Se scegli “no” non potremo proporti offerte e servizi personalizzati che tengano conto delle tue esigenze ed interessi specifici.") }}
            </div>
        </div>
        <div class="flex flex-wrap sm:flex-nowrap">
            <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                <input type="radio" v-model="privacy4" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
            </div>
            <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                <input type="radio" v-model="privacy4" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
            </div>
            <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2" v-html="$t('acconsento al trattamento dei miei dati personali per comunicazione a terzi, a società appartenenti al Gruppo Cofidis ed al Gruppo Crédit Mutuel, per proprie finalità di marketing')">
            </div>
        </div>
        <div class="w-full mb-4 text-red">
            <div v-if="$v.privacy4.$error && !$v.privacy4.required">
                {{ $t("Campo obbligatorio") }}
            </div>
            <div v-if="privacy4 === false">
                {{ $t("Attenzione! Se scegli “no” non potrai ricevere le offerte speciali da parte dei nostri partner.") }}
            </div>
        </div>
        <div slot="after" class="p-4 sm:p-6 md:p-8 pb-12 rounded-lg flex flex-wrap">
            <div class="flex flex-wrap sm:flex-nowrap">
                <div class="relative sm:flex-shrink-0 mr-4 order-2 sm:order-1">
                    <input type="radio" v-model="politics" :value="false" class="form-radio" /><label>{{ $t("no") }}</label>
                </div>
                <div class="relative sm:flex-shrink-0 sm:mr-4 order-3 sm:order-2">
                    <input type="radio" v-model="politics" :value="true" class="form-radio" /><label>{{ $t("sì") }}</label>
                </div>
                <div class="w-full sm:flex-grow order-1 sm:order-3 mb-2">
                    {{ $t("Sono una persona politicamente esposta (PEP)") }}
                </div>
            </div>
            <div class="w-full mb-4 text-red">
                <div v-if="$v.politics.$error && !$v.politics.required">
                    {{ $t("Campo obbligatorio") }}
                </div>
                <div v-if="politics === true">
                    {{ $t("Per favore, verifica la definizione di Persona Politicamente Esposta") }}
                </div>
            </div>
            <div>
                <a href="#" class="underline" @click.prevent="showExplanation = !showExplanation">{{ $t("Clicca qui") }}</a>
                {{ $t("per leggere la definizione di PEP") }}
            </div>
            <SlideUpDown :active="showExplanation" :class="{'mb-5': showExplanation}">
                <div class="py-5 text-gray-600 border-t border-b mt-5">
                    {{ $t("Sono ”persone politicamente esposte” (PEP), le persone fisiche che occupano o hanno cessato di occupare da meno di un anno importanti cariche pubbliche, nonché i loro familiari e coloro che con i predetti soggetti intrattengono notoriamente stretti legami. A titolo esemplificativo (elenco completo indicato nel D.lgs. 231/2007 e successivi aggiornamenti): capi di Stato o di governo, ministri, vice-ministri o sottosegretari, parlamentari, Presidente di Regione, assessore regionale, Sindaco di capoluogo di provincia o città metropolitana, Sindaco di comune con popolazione non inferiore a 15.000 abitanti, nonché cariche analoghe in Stati esteri membri delle corti supreme, delle corti costituzionali o di altri organi giudiziari di alto livello, i membri delle Corti dei Conti e dei Consigli di Amministrazione delle Banche centrali, gli ambasciatori, gli incaricati d’affari e gli ufficiali di alto livello delle forze armate, i membri del Consiglio di Amministrazione, direzione o vigilanza delle imprese possedute dallo Stato, Regioni, Comuni, direttore generale di ATS (ex ASL) e di azienda ospedaliera, di azienda ospedaliera universitaria e degli altri enti del servizio sanitario nazionale.") }}
                </div>
            </SlideUpDown>
        </div>
    </FormCard>
</template>
<script>
import { mapUserData } from "@/utils";
import { required } from "vuelidate/lib/validators";

export default {
    mounted() {
        this.$ga.pageview()
    },
    data() {
        return {
            showExplanation: false
        };
    },
    computed: {
        ...mapUserData(["privacy1", "privacy2", "privacy3", "privacy4", "politics"])
    },
    validations: {
        privacy1: {
            required
        },
        privacy2: {
            required
        },
        privacy3: {
            required
        },
        privacy4: {
            required
        },
        politics: {
            required
        }
    },
    watch: {
        privacy1(val, oldVal) {
            if (val && oldVal === null) {
                this.privacy2 = true;
                this.privacy3 = true;
                this.privacy4 = true;
            }
        },
        showExplanation() {
            this.$store.dispatch("event", { name: "PEPHelperClick" })
        },
        politics() {
            this.$store.dispatch("event", { name: "PEPClick" })
        }
    },
    methods: {
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid && this.privacy1 === true) {
                this.$store.dispatch("event", { name: "PrivacyConsentSubmit" });
                this.$store.dispatch("sendData", { step: "dati_privacy" });    
                this.$store.dispatch("redirect", { step: "dati_privacy", route: "ThankYou" });
            }
        }
    }
};
</script>