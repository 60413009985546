import { v1 as uuidv1 } from "uuid";

export default function() {
  return {
    hasError: false,
    pendingOrder: false,
    
    orderID: uuidv1(),
    importo: 10000,
    rate: null,
    name: "",
    email: "",
    surname: "",
    cellphone: "",
    fiscal_code: "",
    stay_from: null,
    stay_to: null,
    marital_status: null,
    citizenship: null,
    dwelling_type: null,
    monthly_rent: null,
    address: "",
    address_number: "",
    address_cap: "",
    address_multicap: [],
    address_city: "",
    resident_since: "",
    same_addresses: true,
    address2: "",
    address_number2: "",
    address_cap2: "",
    address_multicap2: [],
    address_city2: "",
    identity_type: null,
    document_number: "",
    document_city: "",
    document_expire: null,
    document_release: null,
    
    employment_type: 0,
    family_member_count: 0,
    employed_family_member_count: 0,
    employment_sector: 0,
    occupation: 0,
    monthly_payment: 0,
    monthly_income: null,
    employed_since: null,
    employed_to: null,
    hiring_type: 0,

    employer_name: null,
    employer_address: null,
    employer_street_number: null,
    employer_postal_code: null,
    employer_multicap: [],
    employer_city: null,
    employer_phone: null,

    insurance: false,
    insurance_amount: null,
    insurance_pack: null,
    insurance_available: false,    

    consent_main: null,
    consent1: null,
    consent2: null,
    consent3: null,
    consent4: null,
    consent5: null,
    consent6: null,
    consent7: null,

    day_in_month: null,
    iban: null,
    
    privacy1: null,
    privacy2: null,
    privacy3: null,
    privacy4: null,
    politics: null
  };
}
