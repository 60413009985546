import Vue from 'vue';

/** Set filters */
const formatter = new Intl.NumberFormat("it-IT", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0
});

const formatter_num = new Intl.NumberFormat("it-IT", {
  minimumFractionDigits: 0
});

Vue.filter("eur", function(value) {
  return formatter.format(value);
});

Vue.filter("num", function(value) {
  return formatter_num.format(value);
});