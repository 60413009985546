<template>
	<div>
		 <!-- Video Embed -->
        <div class="media fixed top-0 left-0 w-full h-screen z-50 bg-black bg-opacity-25 flex items-center" :class="{'hidden': !playVideo}">
            <div class="w-full h-screen relative flex items-start sm:items-center justify-center bg-black sm:bg-transparent pt-16 sm:pt-0" :class="{'hidden': !playVideoStarted}">
                <div :id="playerId" class="w-full h-5/6 sm:w-1/2 sm:h-2/3 bg-black"></div>
                <button class="
                    absolute
                    top-3
                    sm:bottom-auto
                    sm:top-14
                    right-3
                    sm:right-2/4
                    sm:transform
                    sm:translate-x-1/2
                    font-semibold
                    text-sm 
                    sm:text-lg
                    text-gray-4
                    bg-orange-1
                    uppercase
                    max-w-xs
                    rounded-full
                    shadow-2xl
                    px-2
                    sm:px-8
                    py-2
                    sm:py-3
                    sm:max-w-none sm:w-auto"
                    @click="stop()">
                        <span class="hidden sm:block">chiudi</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-4 sm:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg> 
                    </button>
            </div>
            <div class="w-full text-center" :class="{'hidden': playVideoStarted}">
                <svg class="animate-spin -ml-1 mr-3 h-8 w-8 text-orange-1 mx-auto inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        </div>
	</div>
</template>
<script>
export default {	
    props: {
    	videoId: {
	      type: String,
	      required: true,
	    }
    },
    data() {
        return {
            player: null,
            playVideo: false,
            playVideoStarted: false
        }
    },
    computed: {
        playerId() {
            return `player${this._uid}`
        }
    },
    methods: {
        init() {
            if(!window.YT) return

            const vm = this 
            this.player = new window.YT.Player(this.playerId, {
                height: '100%',
                width: '100%',
                playerVars: {                    
                    wmode: 'transparent',
                    autoplay: 0,
                    rel: 0,
                    loop: 0,
                    controls: 1,
                    showinfo: 0,
                    autohide: 1,
                    modestbranding: 1,
                    vq: 'hd1080'
                },
                videoId: vm.videoId,
                events: {
                    onStateChange: function(event) {
                        if (event.data == 0) {
                            vm.close()
                        }
                        else {
                            window.setTimeout(function(){
                                vm.playVideoStarted = true
                            }, 1000)
                        }
                    }
                }
            });           
        },
    	play() {
            const fallback = () => {
                let a = document.createElement("a")
                a.classList.add('hidden')
                a.setAttribute('href', `https://www.youtube.com/watch?v=${this.videoId}`)
                a.setAttribute('target','_blank')
                a.click()
            }
            try {
        		this.player.playVideo()
                this.playVideo = true            
            } catch(ex) {
                fallback()
            } 
    	},
    	stop() {
            this.playVideo = false
            this.playVideoStarted = false
            this.player.stopVideo()
        },
    	close() {
			this.playVideoStarted = false 
            this.playVideo = false    		
    	}
    }
}
</script>