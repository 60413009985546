<template>
    <PrestitoHome />
</template>
<script>
// @ is an alias to /src
import PrestitoHome from "@/views/themes/prestito/Home";

export default {
    name: "Home",
    components: {
        PrestitoHome
    },
    mounted() {
        //OneTrust Cookies Consent Notice 
        if( document.getElementById('onetrust') ) return;

        var script = document.createElement("script");
        script.id = "onetrust";
        script.src = "https://cdn.cookielaw.org/consent/587db2df-c576-44e0-98d8-52bff485d723/OtAutoBlock.js";
        document.head.appendChild(script);

        script = document.createElement("script");
        script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
        script.setAttribute('data-document-language', 'true');
        script.setAttribute('data-domain-script', '587db2df-c576-44e0-98d8-52bff485d723');
        document.head.appendChild(script);

        window.OptanonWrapper = function() {
            window.event.$emit('cookiePreferencesUpdated');
        }
    }
};
</script>