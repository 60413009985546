<template>
    <div class="cursor-pointer flex flex-col rounded-lg px-0 py-2"  @click="$emit('change', value)" style="-webkit-tap-highlight-color: transparent;">
        <div class="h-16 w-16 mx-auto rounded-full flex items-center justify-center" :class="wrapperClass">
            <img class="block h-12" src="@/assets/images/ms/celibe.svg" v-if="value == 1" />
            <img class="block h-12" src="@/assets/images/ms/divorziato.svg" v-if="value == 2" />
            <img class="block h-12" src="@/assets/images/ms/coniugato.svg" v-if="value == 3" />
            <img class="block h-12" src="@/assets/images/ms/convivente.svg" v-if="value == 4" />
            <img class="block h-12" src="@/assets/images/ms/separato.svg" v-if="value == 5" />
            <img class="block h-12" src="@/assets/images/ms/vedovo.svg" v-if="value == 6" />
        </div>
        <span class="block text-center" v-html="label"></span>
    </div>
</template>
<script>
export default {
    model: {
        prop: "modelValue",
        event: "change"
    },
    props: {
        modelValue: {
            default: ""
        },
        value: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            default: ""
        },
        hasError: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        wrapperClass() {
            return {
                "bg-form": this.value != this.modelValue,
                "bg-yellow": this.value == this.modelValue,
                "border-red": this.hasError
            };
        }
    }
};
</script>