<template>
    <div class="w-full">
        <!-- Video Embed -->
        <div v-if="videoFallback" class="rounded-3xl w-full bg-black flex items-center justify-center" :style="`height:${height}`">
            <svg @click="play()" xmlns="http://www.w3.org/2000/svg" class="text-white h-16 w-16 block cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </div>
        <div :id="playerId" class="rounded-3xl w-full" :style="`height:${height};display:none;`" ></div>
        <div v-if="videoEnd " class="rounded-3xl w-full bg-black absolute top-0 left-0 flex items-center justify-center" :style="`height:${height}`">
            <svg @click="play()" xmlns="http://www.w3.org/2000/svg" class="text-white h-16 w-16 block cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </div>
    </div>
</template>
<script>
export default {    
    props: {
        videoId: {
          type: String,
          required: true,
        },
        height: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            player: null,
            videoEnd: false,  
            videoFallback: true          
        }
    },
    computed: {
        playerId() {
            return `player${this._uid}`
        }
    },
    methods: {
        init() {
            if (!window.YT) return
            
            const vm = this            
            document.getElementById(this.playerId).style.display = 'block'

            this.videoFallback = false
            this.player = new window.YT.Player(this.playerId, {
                height: '100%',
                width: '100%',
                playerVars: {                    
                    wmode: 'transparent',
                    autoplay: 0,
                    rel: 0,
                    loop: 0,
                    controls: 1,
                    showinfo: 0,
                    autohide: 1,
                    modestbranding: 1,
                    vq: 'hd1080'
                },
                videoId: vm.videoId,
                events: {
                    onStateChange: function(event) {
                        if (event.data == 0) vm.videoEnd = true
                    }
                }
            });
        },
        play() {
            const fallback = () => {
                let a = document.createElement("a")
                a.classList.add('hidden')
                a.setAttribute('href', `https://www.youtube.com/watch?v=${this.videoId}`)
                a.setAttribute('target','_blank')
                a.click()
            }
            try {
                this.player.playVideo()
                this.videoEnd = false
            } catch(ex) {
                fallback()
            }  
        },
        stop() {
            this.player.stopVideo()
        }
    }
}
</script>