import loadData from "@/dataService.js";
import axios from "axios";
import steps from "./steps";
import events from "./events";
import router from '@/router'

const config = loadData("config.json");
const requireJs = require.context("@", true, /ext\/\w+\.js$/);

export default {
    redirect({commit, state, getters}, { step, route }) {
        // log steps
        commit('logStep', step)
        // theme defined data append
        if (config.hooks && config.hooks[step] && config.hooks[step].includes(process.env.NODE_ENV)) {
            const hooksUrl = `./views/themes/${state.theme}/ext/hooks.js`
            let { default: hooks } = requireJs(hooksUrl)
            hooks[step](router, {commit, state, getters}, route)
        }
        else router.push({ name: route })
    },
  
    event({ state }, { name, source = {}, model = null }) {
        const vm = this._vm

        events[name](state, vm, source, model)    
        // theme defined events
        if (config.event && config.event[name] && config.event[name].includes(process.env.NODE_ENV)) {
            const eventUrl = `./views/themes/${state.theme}/ext/event.js`
            let { default: event } = requireJs(eventUrl)
            event[name](state, vm, model)
        }    
    },
    
    async verifyCode({ state }, { code = null} ) {
        let payload = {
            envType: config.envType,
            envName: config.envName
        }

        return new Promise((resolve, reject) => {
            axios.post(state.serverUrl + `access_code/${code}`, payload)
                .then(function(response) {
                    state.protected = !response.data.success                    
                    resolve(response.data.success)
                })
                .catch(function() {
                    state.protected = true;
                    reject(false)
                })
        })
    },    

    async loadPrecompiledData({ state }, { url }) {
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function(response) {
                if (response.data.order.StartRobot) 
                    state.userData.pendingOrder = true 

                else if (response.data.success == true)
                    resolve(response.data.order.step_precompile)    

                reject(response)            
            })
            .catch(function(response) {
                state.userData.hasError = true
                reject(response)
            })
        });
    },

    async sendData({commit, state, getters}, { step }) {
        let payload = steps[step] ? steps[step](state) : {}

        payload["step"] = step
        payload["orderID"] = state.userData.orderID        
        // theme defined data append
        if (config.append && config.append[step] && config.append[step].includes(process.env.NODE_ENV)) {
            const appendUrl = `./views/themes/${state.theme}/ext/append.js`
            let { default: append } = requireJs(appendUrl)
            append[step](payload, {commit, state, getters})
        }
        // add env type and name if set
        if (state.envType) payload["envType"] = state.envType        
        if (state.envName) payload["envName"] = state.envName

        // output to console in development env
        if (process.env.NODE_ENV === "development") {
            console.log("Mock data sending", state.serverUrl, payload)
            return
        }
        // last call is POST
        if (step == "dati_privacy") {                       
            await axios.post(state.serverUrl, payload)
        } else {
            await axios.put(state.serverUrl, payload)
                .then(function(response) {
                    if (response.data.success != true)
                        state.userData.hasError = true
                })
                .catch(function() {
                    state.userData.hasError = true
                })
        }
    }
};