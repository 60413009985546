export const JOB_TYPES = {
    AUTONOMO: 1,
    PROFESSIONISTA: 11,
    DIPENDENTE: 2,
    STAGIONALE: 3,
    CASALINGA: 4,
    DISOCCUPATO: 5,
    PENSIONATO: 6,
    PENSIONATO_INVALIDO: 7,
    REDDITIERE: 9,
    STUDENTE: 10
}

export const HIRING_TYPES = {
    DETERMINATO: 1,
    INDETERMINATO: 2
}
export default {JOB_TYPES, HIRING_TYPES}