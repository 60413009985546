export const BackClick = (state, vm, source, model) => {    
    vm.$gtm.dispatch("BackClick", { event_label: model })
}

export const DocumentClick = (state, vm, source) => {
    vm.$gtm.dispatch("DocumentClick", { event_label: source.label })
}

export const RataClick = (state, vm, source) => {
    const imp_rata = parseFloat(source.model.imp_rata.replace(",", "."))

    vm.$gtm.dispatch("RataClick", { value: parseFloat(imp_rata) })
    vm.$adf.event("RataClick", { sales: imp_rata })
    vm.$fbq.event('trackCustom', "RataClick", { value: imp_rata })
    vm.$quantcast.event("_fp.event.Valore Rata_1")
}

export const RataUpgradeClick = (state, vm, source) => {
    const imp_rata = parseFloat(source.model.imp_rata.replace(",", "."))

    vm.$gtm.dispatch("RataUpgradeClick", { value: imp_rata })
    vm.$adf.event("RataUpgradeClick", { sales: imp_rata })
    vm.$fbq.event('trackCustom', "RataUpgradeClick", { value: imp_rata })
    vm.$quantcast.event("_fp.event.Valore_Rata_offerta")
}

export const PEPHelperClick = (state, vm) => {
    vm.$gtm.dispatch("PEPHelperClick")
}

export const PEPClick = (state, vm) => {
    vm.$gtm.dispatch("PEPClick", { event_label: state.userData.politics ? "si" : "no" })
}

export const LoadProtectionLoaded = (state, vm) => {
    vm.$ga.event('Assicurazione', { 'event_category': 'Form' })
}

export const LoadProtectionConsentLoaded = (state, vm) => {
    vm.$ga.event('Assicurazione Consenso', { 'event_category': 'Form' })
}

export const LoadProtectionWarningLoaded = (state, vm) => {
    vm.$ga.event('Assicurazione Avviso', { 'event_category': 'Form' })
}

export const FormImportoSubmit = (state, vm) => {
    vm.$gtm.dispatch("FormImportoSubmit", { value: state.choosenCredit })
    vm.$adf.event("FormImportoSubmit", { sales: state.choosenCredit })
    vm.$fbq.event('trackCustom', "FormImportoSubmit", { value: state.choosenCredit })
    vm.$quantcast.event("_fp.event.Continua_1")
}

export const ProposalLimitedSubmit = (state, vm, source) => {
    vm.$gtm.dispatch("ProposalLimitedSubmit", {
        value: parseFloat(source.model.imp_finanz)
    });

    vm.$adf.event("ProposalLimitedSubmit", { sales: source.model.imp_finanz });
    vm.$fbq.event('trackCustom', "ProposalLimitedSubmit", { value: source.model.imp_finanz });
}

export const ContattiSubmit = (state, vm) => {
    vm.$gtm.dispatch("ContattiSubmit", {
        value: state.userData.importo
    })
    vm.$gtm.dispatch("LeadConversion", {
        value: state.userData.importo
    })
    vm.$fbq.event('track', "ContattiSubmit", {
        value: state.userData.importo
    })
    vm.$adf.event("ContattiSubmit")

    vm.$quantcast.event("_fp.event.Lead_Prestito", {
        orderid: state.userData.orderID,
        revenue: state.userData.importo
    })
}

export const PrivacyConsentSubmit = (state, vm) => {
    vm.$cxmtrxq(state.userData);

    vm.$younited(state.userData);

    vm.$adf.event("PrivacyConsentSubmit", {
        sales: state.userData.importo,
        transaction_id: state.userData.orderID
    });
    vm.$gtm.dispatch("PrivacyConsentSubmit", {
        value: state.userData.importo,
        transaction_id: state.userData.orderID
    })
    vm.$gtm.dispatch("LoanRequest", {
        value: state.userData.importo
    });    
    vm.$fbq.event('track', "PrivacyConsentSubmit", {
        value: state.userData.importo,
        currency: 'EUR'
    });
    vm.$quantcast.event("_fp.event.Richiesta Prestito", {
        orderid: state.userData.orderID,
        revenue: state.userData.importo
    })
}

export const PersonalData1Submit = (state, vm) => {
    vm.$gtm.dispatch("PersonalData1Submit");
    vm.$adf.event("PersonalData1Submit");
    vm.$fbq.event('trackCustom', "PersonalData1Submit");
    vm.$quantcast.event("_fp.event.Continua_3")
}

export const PersonalData2Submit = (state, vm) => {
    vm.$adf.event("PersonalData2Submit");
    vm.$fbq.event('trackCustom', "PersonalData2Submit");
    vm.$gtm.dispatch("PersonalData2Submit");
    vm.$quantcast.event("_fp.event.Continua_4")
}

export const DeliveryDataSubmit = (state, vm) => {
    vm.$adf.event("DeliveryDataSubmit")
    vm.$fbq.event('trackCustom', "DeliveryDataSubmit")
    vm.$gtm.dispatch("DeliveryDataSubmit");
    vm.$quantcast.event("_fp.event.Continua_5")
}

export const IdentityCardSubmit = (state, vm) => {
    vm.$gtm.dispatch("IdentityCardSubmit")
    vm.$adf.event("IdentityCardSubmit")
    vm.$fbq.event('trackCustom', "IdentityCardSubmit")
    vm.$quantcast.event("_fp.event.Continua_6")
}

export const IncomeDataSubmit = (state, vm) => {
    vm.$gtm.dispatch("IncomeDataSubmit")
    vm.$adf.event("IncomeDataSubmit")
    vm.$fbq.event('trackCustom', "IncomeDataSubmit")
    vm.$quantcast.event("_fp.event.Continua_7")
}

export const LoanProtectionSubmit = (state, vm) => {
    vm.$gtm.dispatch("LoanProtectionSubmit")    
    vm.$adf.event("LoanProtectionSubmit")
    vm.$fbq.event('trackCustom', "LoanProtectionSubmit")
    vm.$quantcast.event("_fp.event.Continua_7 1")
}

export const LoanProtectionConsentSubmit = (state, vm) => {
    vm.$gtm.dispatch("LoanProtectionConsentSubmit")    
    vm.$adf.event("LoanProtectionConsentSubmit")
    vm.$fbq.event('trackCustom', "LoanProtectionConsentSubmit")
    vm.$quantcast.event("_fp.event.Continua_7 2")
}

export const LoanProtectionWarningSubmit = (state, vm) => {
    vm.$gtm.dispatch("LoanProtectionWarningSubmit")    
    vm.$adf.event("LoanProtectionWarningSubmit")
    vm.$fbq.event('trackCustom', "LoanProtectionWarningSubmit")
    vm.$quantcast.event("_fp.event.Continua_7 3")
}

export const EmployerDataSubmit = (state, vm) => {
    vm.$gtm.dispatch("EmployerDataSubmit")
    vm.$adf.event("EmployerDataSubmit")
    vm.$fbq.event('trackCustom', "EmployerDataSubmit")
    vm.$quantcast.event("_fp.event.Continua_8")
}

export const PaymentDetailsSubmit = (state, vm) => {
    vm.$adf.event("PaymentDetailsSubmit")
    vm.$fbq.event('trackCustom', "PaymentDetailsSubmit")
    vm.$gtm.dispatch("PaymentDetailsSubmit")
    vm.$quantcast.event("_fp.event.Continua_9")
}

export default {
    BackClick,
    DocumentClick,
    RataClick,
    RataUpgradeClick,
    PEPHelperClick,
    PEPClick,
    LoadProtectionLoaded,
    LoadProtectionConsentLoaded,
    LoadProtectionWarningLoaded,
    FormImportoSubmit,
    ProposalLimitedSubmit,
    ContattiSubmit,
    PrivacyConsentSubmit,
    PersonalData1Submit,
    PersonalData2Submit,
    DeliveryDataSubmit,
    IdentityCardSubmit,
    IncomeDataSubmit,
    LoanProtectionSubmit,
    LoanProtectionConsentSubmit,
    LoanProtectionWarningSubmit,
    EmployerDataSubmit,
    PaymentDetailsSubmit
}