import loadData from "@/dataService";
const config = loadData("config.json");
const adfevents = loadData("adfevents.json");

class ADF {
    constructor(config) {
        this.enabled = config.enabled ? config.enabled : false;
        this.track_id = config.id ? config.id : -1;
        this.pagename = config.pagename ? config.pagename : "";
        if (this.enabled) {
            this.initalize();
        }
    }
    initalize() {
        window._adftrack = Array.isArray(window._adftrack) ?
            window._adftrack :
            window._adftrack ?
            [window._adftrack] :
            [];
        window._adftrack.push({
            HttpHost: 'track.adform.net',
            pm: this.track_id,
            divider: encodeURIComponent("|"),
            pagename: encodeURIComponent(this.pagename)
        });
        (function() {
            var s = document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://s2.adform.net/banners/scripts/st/trackpoint-async.js";
            var x = document.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
        })();
    }
    event(title, data = {}) {   
        if (!this.enabled) return;
        // check if event is defined     
        if(!this.enabled || !adfevents[title]) return;

        if (process.env.NODE_ENV === "development") {
            console.log("Mock adform tracking", adfevents[title], data);
        } 
        else {
            window.adf.ClickTrack(this, this.track_id, adfevents[title], data)
        }
    }
}
export default new ADF(config.tracking.adf ?? {});