<template>    
    <li class="proposal-other open text-base sm:text-lg lg:text-xl flex flex-wrap border border-yellow border-t-0 lg:border-t lg:flex-no-wrap w-full text-gray-700 rounded-3xl lg:rounded-full lg:overflow-hidden mb-8 mt-2 lg:mb-0 lg:mt-0" :key="`po_${model.id}`" v-if="active">
        <div class="lg:w-1/4 bg-yellow w-full flex items-center lg:justify-between px-2 rounded-t-3xl lg:rounded-t-lg lg:rounded-tr-none lg:border-r">            
            <span class="ml-2 lg:ml-0 mr-auto lg:mr-0 p-2 whitespace-no-wrap font-semibold">{{ model.imp_finanz_text }}</span>
            <a class="sm:text-base underline mr-4" target="_blank" :href="secciUrl">SECCI</a>
        </div>
        <div class="lg:w-1/2 w-full flex mt-0 items-center justify-center p-2">
            <p v-if="text" v-html="text">
            <p v-else>                
                <strong>{{ model.imp_rata }}</strong> € {{ $t("per") }}
                <strong>{{ model.rate }}</strong> {{ $t("mesi") }} {{ $t("TAEG") }}
                <strong>{{ model.taeg }}</strong>%
            </p>
        </div>
        <div class="w-full lg:w-auto lg:ml-auto text-center lg:text-right -mb-5 lg:-mb-0">
            <button class="bg-yellow rounded-full p-2 px-16 text-base font-bold sm:mt-0.5 sm:mr-0.5 shadow-xl lg:shadow-none hover:bg-yellow-4" @click="onSubmit">
                {{ $t("CONTINUA") }}
            </button>
        </div>
    </li>    
    <li class="proposal-other close text-xs ipp:text-sm sm:text-lg lg:text-xl flex flex-wrap items-center bg-white lg:flex-no-wrap w-full text-gray-700 rounded-full border border-border lg:overflow-hidden mb-2" :key="`po_${model.id}`" v-else @click="$emit('selected', `${model.imp_finanz}x${model.rate}`)">
        <div class="p-1 lg:flex-initial hidden lg:block">
            <strong class="xl:ml-10 0 p-2 whitespace-no-wrap">{{ model.imp_finanz_text }}</strong>
        </div>
        <div class="flex-1 lg:flex-initial pr-3 justify-start self-stretch lg:border-r border-border flex items-center lg:justify-center lg:w-auto lg:ml-auto hidden lg:flex">        
            <a class="sm:text-base underline" target="_blank" :href="secciUrl">SECCI</a>
        </div>
        <div class="btn-wrapper flex-1 lg:flex-initial text-right lg:w-1/4 lg:order-last hidden lg:block">
            <button class="bg-yellow rounded-full p-2 px-16 text-base font-bold lg:mr-0.5 shadow-xl lg:shadow-none hover:bg-yellow-4" @click="onSubmit()">
                {{ $t("CONTINUA") }}
            </button>
        </div>
        <div class="w-auto py-2 pl-4 pr-1 lg:flex-initial lg:w-1/2 lg:text-center">
            <p v-if="textAlt" v-html="textAlt">
            <p v-else>
                <strong class="lg:hidden">{{ model.imp_finanz_text }}</strong>
                <span class="lg:hidden ml-1 sm:ml-4 mr-1">rata</span>
                <strong>{{ model.imp_rata }}</strong> € {{ $t("per") }}
                <strong>{{ model.rate }}</strong> {{ $t("mesi") }} {{ $t("TAEG") }}
                <strong>{{ model.taeg }}</strong>%
            </p>
        </div>
    </li>
</template>
<script>
export default {
    props: {
        model: {
            type: Object,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: null
        },
        textAlt: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        }
    },
    computed: {
        secciUrl() {
            return this.url ?? `${this.baseUrl}${this.model.imp_finanz}_${this.model.rate}.pdf`
        },
        baseUrl() {
            return this.$store.state.secciUrl
        },
        isMobile: function() {
            return window.innerWidth < 1024
        }
    },
    methods: {
        onSubmit() {            
            this.$store.commit("setChoosenRata", this.model.rate);
            this.$store.commit("upadateUserCreditData");
            if (this.$route.name === "RataUpgrade") {
                this.$store.dispatch("event", { name: "RataUpgradeClick", source: this });
            } else {
                this.$store.dispatch("event", { name: "RataClick", source: this });
            }
            this.$emit('rataClick', this.model.imp_finanz, this.model.rate)    
            this.$store.dispatch("redirect", { step: "proposal_other", route: "Contatti" });
        }
    }
};
</script>