<template>
    <PrestitoFooter />
</template>
<script>
// @ is an alias to /src
import PrestitoFooter from "@/views/themes/prestito/Footer";

export default {
    name: "Footer",
    components: {
        PrestitoFooter
    }
};
</script>