import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
const requireData = require.context("@", true, /[A-Za-z0-9-_,\s]+\.json$/);

Vue.use(VueI18n)

function loadMessages () {
  // const messages = {}
  // // add default translations
  // const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i) 
  // locales.keys().forEach(key => {
  //   const matched = key.match(/([A-Za-z0-9-_]+)\./i)
  //   if (matched && matched.length > 1) {
  //     const locale = matched[1]
  //     messages[locale] = locales(key)
  //   }
  // })
  const context = require.context("./locales", true, /[a-z0-9-_]+\.json$/i);

  const messages = context
    .keys()
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce(
      (messages, { key, locale }) => ({
        ...messages,
        [locale]: context(key),
      }),
      {}
    );
    
  // add theme translations if any
  store.state.locales.map(locale => {
    const path = `./views/themes/${store.state.theme}/locales/${locale}.json`;
    try {
      const trans = requireData(path)
      for(var key in trans) {
        messages[locale][key] = trans[key]
      }    
    } catch (e) {
        console.log("theme locale not found :", path);               
    }
  })

  return { context, messages }
}

const { context, messages } = loadMessages();

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'it',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'it',
  messages,
  silentTranslationWarn: true
})

// Hot updates
if (module.hot) {
  module.hot.accept(context.id, () => {
    const { messages: newMessages } = loadMessages();

    Object.keys(newMessages)
      .filter((locale) => messages[locale] !== newMessages[locale])
      .forEach((locale) => {
        messages[locale] = newMessages[locale];
        i18n.setLocaleMessage(locale, messages[locale]);
      });
  });
}

export default i18n
