import Vue from 'vue'

Vue.mixin({
	data() {
		return {
			focusInput: null
		}
	},
	methods: {
		setFocusInput(id) {
            this.focusInput = id
        },
        unsetFocusInput() {
            this.focusInput = null
        },
        focusFirstError() {
            Vue.nextTick(function() {
                var el = document.getElementsByClassName("border-red")
                if (el.length) el[0].parentElement.scrollIntoView({ behavior: 'smooth' })                
            })
        }
    }
})