<template>
    <FormCard @submit="onSubmit" title="Dati di reddito">
        <div class="w-full lg:w-1/3 lg:pr-4 mb-2">
            <FloatingLabelSelect :name="`employment_type`" v-model="employment_type" :items="employment_types" :label="`Tipo impiego`" :disableZeroValue="true" :error="$v.employment_type.$error"/>
            <div class="h-6 w-full text-red" v-if="$v.employment_type.$error">
                <small v-if="!$v.employment_type.nonZero">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div v-if="showEmploymentSector" class="w-full lg:w-1/3 lg:pr-4 mb-2">
            <FloatingLabelSelect :name="`employment_sector`" v-model="employment_sector" :items="employmentSectors" :label="`Settore impiego`" :disableZeroValue="true" :error="$v.employment_sector.$error"/>
            <div class="h-6 w-full text-red" v-if="$v.employment_sector.$error">
                <small v-if="!$v.employment_sector.nonZero">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div v-if="showMonthlyPayment && !showHiringType" class="w-full lg:w-1/3 lg:pr-4 mb-2">
            <FloatingLabelSelect :name="`monthly_payment`" v-model="monthly_payment" :items="monthly_payments" :label="`Mensilità`" :disableZeroValue="true" :error="$v.monthly_payment.$error"/>
            <div class="h-6 w-full text-red" v-if="$v.monthly_payment.$error">
                <small v-if="!$v.monthly_payment.nonZero">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div v-if="showOccupations" class="w-full lg:w-1/3 lg:pr-4 mb-2">
            <FloatingLabelSelect :name="`occupation`" v-model="occupation" :items="occupations" :label="`Impiego attuale`" :disableZeroValue="true" :error="$v.occupation.$error"/>
            <div class="h-6 w-full text-red" v-if="$v.occupation.$error">
                <small v-if="!$v.occupation.nonZero">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-full"></div>
        <div v-if="showHiringType" class="w-full lg:w-1/3 lg:pr-4 mb-2">
            <FloatingLabelSelect :name="`hiring_type`" v-model="hiring_type" :items="hiring_types" :label="`Assunzione a tempo`" :disableZeroValue="true" :error="$v.hiring_type.$error"/>
            <div class="h-6 w-full text-red" v-if="$v.hiring_type.$error">
                <small v-if="!$v.hiring_type.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div v-if="showMonthlyPayment && showHiringType" class="w-full lg:w-1/3 lg:pr-4 mb-2">
            <FloatingLabelSelect :name="`monthly_payment`" v-model="monthly_payment" :items="monthly_payments" :label="`Mensilità`" :disableZeroValue="true" :error="$v.monthly_payment.$error"/>
            <div class="h-6 w-full text-red" v-if="$v.monthly_payment.$error">
                <small v-if="!$v.monthly_payment.nonZero">
                    {{ $t("Campo obbligatorio") }}
                </small>
            </div>
        </div>
        <div class="w-full"></div>
        <template v-if="showMonthlyIncome">
            <div class="w-full lg:w-1/3 lg:pr-4 mb-2" :class="{'lg:mt-6': showEmployedSince}">
                <FloatingLabelInput :name="`monthly_income`" :type="`tel`" v-model="monthly_income" :mask="currencyMask" :label="`Reddito mensile netto`" :error="$v.monthly_income.$error"/>
                <div class="h-6 w-full text-red" v-if="$v.monthly_income.$error">
                    <small v-if="!$v.monthly_income.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
        </template>
        <div v-if="showEmployedSince" class="w-full lg:w-1/3 lg:pr-4 mb-2">
            <label class="text-xs">{{ $t("Occupazione dal") }}</label>
            <div class="date-select" :class="{ 'border-red': $v.employed_since.$error }" >    
                <DateSelect :value="employed_since" :showDay="false" :inputClass="{ 'border-red': $v.employed_since.$error }" :startYear="employedSinceStartYear" @change="updateEmployedSinceValue" ref="emplyoedSinceComponent" />
            </div>
            <div class="h-6 w-full text-red" v-if="$v.employed_since.$error">
                <small v-if="!$v.employed_since.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="!$v.employed_since.minValue">
                    {{ $t("Data non valida") }}
                </small>
                <small v-else-if="!$v.employed_since.maxValue">
                    {{ $t("Data non valida") }}
                </small>
            </div>
        </div>
        <div v-if="showEmployedTo" class="w-full lg:w-1/3 lg:pr-4 mb-2">
            <label class="text-xs">{{ $t("Occupazione al") }}</label>
            <DateSelect :value="employed_to" :showDay="false" :inputClass="{ 'border-red': $v.employed_to.$error }" :startYear="employedToStartYear" :endYear="employedToEndYear" @change="updateEmployedToValue" ref="employedToComponent" />
            <div class="h-6 w-full text-red">
                <small v-if="$v.employed_to.$error && !$v.employed_to.required">
                    {{ $t("Campo obbligatorio") }}
                </small>
                <small v-else-if="$v.employed_to.$error && !$v.employed_to.minValue">
                    {{ $t("Data non valida") }}
                </small>
            </div>
        </div>
        <div class="w-full flex flex-wrap">
            <div class="w-full lg:w-1/3 lg:pr-4 mb-2" >
                <FloatingLabelSelect :name="`family_member_count`" v-model="family_member_count" :items="family_member_counts" :label="`Numero di familiari, incluso te stesso`" :error="$v.family_member_count.$error" :disableZeroValue="true"/>
                <div class="h-6 w-full text-red" v-if="$v.family_member_count.$error">
                    <small v-if="!$v.family_member_count.nonZero">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
            <div class="w-full lg:w-1/3 lg:pr-4 mb-2" v-if="family_member_count">
                <FloatingLabelSelect :name="`employed_family_member_count`" v-model="employed_family_member_count" :items="employed_family_member_counts" :label="`Familiari con Reddito, incluso te stesso`" :error="$v.employed_family_member_count.$error" :disableZeroValue="true"/>
                <div class="h-6 w-full text-red" v-if="$v.employed_family_member_count.$error">
                    <small v-if="!$v.employed_family_member_count.required">
                        {{ $t("Campo obbligatorio") }}
                    </small>
                </div>
            </div>
        </div>
        <div v-if="insuranceAvailable" class="w-full mb-4 text-sm">
            {{ $t("Le informazioni inserite potranno essere utilizzate per compilare il questionario per la valutazione dei bisogni e delle esigenze assicurative del cliente e dell'adeguatezza del contratto") }}
        </div>
    </FormCard>
</template>
<script>
import { JOB_TYPES, HIRING_TYPES } from '@/constants.js';
import { mapUserData, getBirthday, nonZero, nonZeroIf } from "@/utils";
import { currencyMask, shortDateMask } from "@/masks";
import { requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import moment from "moment";

import DateSelect from "@/components/DateSelect";
import FloatingLabelSelect from "@/components/FloatingLabelSelect";
import FloatingLabelInput from "@/components/FloatingLabelInput";
import loadData from "@/dataService.js";

export default {
    components: { DateSelect, FloatingLabelSelect, FloatingLabelInput },
    mounted() {
        this.$ga.pageview()
    },
    data() {
        return {
            employment_types: loadData("attivita.json"),
            all_employment_sectors: loadData("settore.json"),
            all_occupations: loadData("occupazione.json"),        
            currencyMask,
            shortDateMask,
            hiring_types: [{
                key: 0,
                value: 'Assunzione a tempo'
            },
            {
                key: 1, 
                value: 'Determinato'
            },
            {
                key: 2,
                value: 'Indeterminato'
            }],
            monthly_payments: [{
                    key: 0,
                    value: 'Mensilità'
                },
                {
                    key: 2,
                    value: "12"
                },
                {
                    key: 3,
                    value: "13"
                },
                {
                    key: 4,
                    value: "14"
                },
                {
                    key: 5,
                    value: "15"
                },
                {
                    key: 6,
                    value: "16"
                }
            ]
        };
    },
    computed: {
        ...mapUserData([
            "employment_type",
            "family_member_count",
            "employed_family_member_count",
            "employment_sector",
            "occupation",
            "monthly_payment",
            "monthly_income",
            "employed_since",
            "employed_to",
            "hiring_type",
            "insurance_pack",
            "insurance_available",
            "financial_problems"
        ]),
        ...mapGetters(["insurancePackage", "insuranceAvailable"]),
        showEmploymentSector() {
            return !!(
                [JOB_TYPES.AUTONOMO,JOB_TYPES.DIPENDENTE,JOB_TYPES.STAGIONALE,JOB_TYPES.PROFESSIONISTA].includes(this.employment_type) &&
                this.all_employment_sectors[this.employment_type].length
            );
        },
        showMonthlyPayment() {
            return [JOB_TYPES.PENSIONATO,JOB_TYPES.PENSIONATO_INVALIDO].includes(this.employment_type) || 
                (   this.employment_type == JOB_TYPES.DIPENDENTE && this.occupation)
        },
        showEmployedSince() {
            return ([JOB_TYPES.AUTONOMO,JOB_TYPES.DIPENDENTE,JOB_TYPES.STAGIONALE].includes(this.employment_type) && this.occupation) ||
                    (this.employment_type === JOB_TYPES.PROFESSIONISTA && this.employment_sector)
        },
        showEmployedTo() {
            return (this.employment_type == JOB_TYPES.STAGIONALE || this.hiring_type == HIRING_TYPES.DETERMINATO) && this.occupation
        },
        showHiringType() {
            return this.employment_type === JOB_TYPES.DIPENDENTE && this.occupation
        },
        showOccupations() {
            return !!this.occupations.length
        },
        showMonthlyIncome() {
            return [JOB_TYPES.PENSIONATO,JOB_TYPES.PENSIONATO_INVALIDO,JOB_TYPES.REDDITIERE].includes(this.employment_type) ||
                    (this.employment_type === JOB_TYPES.PROFESSIONISTA && this.employment_sector) ||
                    this.occupation
        },
        employmentSectors() {
            return this.all_employment_sectors[this.employment_type].map(
                (o, index) => {
                    let value = o;
                    let key = index;
                    if (typeof value === "object") {
                        key = Object.keys(value)[0];
                        value = Object.values(value)[0];
                    }
                    return {
                        key,
                        value
                    };
                }
            );
        },
        occupations() {
            let occupations = this.all_occupations[this.employment_type][
                this.employment_sector
            ];
            if (!Array.isArray(occupations)) return [];
            return occupations.map((o, index) => {
                let value = o;
                let key = index;
                if (typeof value === "object") {
                    key = Object.keys(value)[0];
                    value = Object.values(value)[0];
                }
                return {
                    key,
                    value
                };
            });
        },
        family_member_counts() {            
            let arr = [1,2,3,4,5,6,7,8,9].map(i => ({key: i, value: i}))
            arr.unshift({key: 0, value: 'Numero di familiari, incluso te stesso'})        
            return arr
        },
        employed_family_member_counts() {   
            let arr = [{key: 0, value: 'Familiari con Reddito, incluso te stesso'}]
            for(var i = 1; i <= this.family_member_count; i++) {
                arr.push({key: i, value: i})
            }    
            return arr
        },
        employedSinceStartYear() {
            return parseInt(moment(getBirthday(), 'MM/YYYY').format('YYYY'))
        },
        employedToStartYear() {
            return parseInt(moment().format('YYYY'))
        },
        employedToEndYear() {
            return parseInt(moment().add(20, "years").format('YYYY'))
        }
    },
    validations: {
        employment_type: {
            nonZero
        },
        employment_sector: {
            nonZero: nonZeroIf("showEmploymentSector")
        },
        monthly_payment: {
            nonZero: nonZeroIf("showMonthlyPayment")
        },
        occupation: {
            nonZero: nonZeroIf("showOccupations")
        },
        monthly_income: {
            required: requiredIf("showMonthlyIncome")
        },
        employed_since: {
            required: requiredIf("showEmployedSince"),
            minValue: (value, vm) => {
                return vm.showEmployedSince ? moment(value, 'MM/YYYY') >= moment(getBirthday(), 'MM/YYYY') : true
            },
            maxValue: (value, vm) => {
                return vm.showEmployedSince ? moment(value, 'MM/YYYY') <= moment() : true
            }
        },
        employed_to: {
            required: requiredIf("showEmployedTo"),            
            minValue: (value, vm) => {
                return vm.showEmployedTo ? moment(value, 'MM/YYYY') >= moment() : true
            },
        },
        hiring_type: {
            nonZero: nonZeroIf("showHiringType")
        },
        family_member_count: {
            nonZero
        },
        employed_family_member_count: {
            nonZero
        }
    },
    watch: {
        hiring_type() {
            this.employed_to = null
        },
        showEmploymentSector(val) {
            if (val) {
                this.$v.employment_sector.$reset();
            }
        },
        showOccupations(val) {
            if (val) {
                this.$v.occupation.$reset();
            }
        },
        showMonthlyPayment(val) {
            if (val) {
                this.$v.monthly_payment.$reset();
            }
        },
        showMonthlyIncome(val) {
            if (val) {
                this.$v.monthly_income.$reset();
            }
        },
        showEmployedSince(val) {
            if (val) {
                this.$v.employed_since.$reset();
            }
        },
        showEmployedTo(val) {
            if (val) {
                this.$v.employed_to.$reset();
            }
        },
        showHiringType(val) {
            if (val) {
                this.$v.hiring_type.$reset();
            }
        },
        family_member_count(val) {
            if (val === 1) {
                this.employed_family_member_count = 1;
                return;
            }
            if (val < this.employed_family_member_count)
                this.employed_family_member_count = val;
        },
        employment_type() {
            this.employment_sector = 0;
            this.family_member_count = 0;
            // this.$v.family_member_count.$reset();
            this.employed_family_member_count = 0;
            // this.$v.employed_family_member_count.$reset();
            this.occupation = 0;
            this.monthly_payment = 0;
            this.monthly_income = null;
            this.employed_since = null;
            this.employed_to = null;
            this.hiring_type = 0;
            if (this.$refs.emplyoedSinceComponent) this.$refs.emplyoedSinceComponent.reset();
            if (this.$refs.emplyoedToComponent) this.$refs.emplyoedToComponent.reset();
        },
        employment_sector() {
            this.occupation = 0;
        }
    },
    methods: {
        updateEmployedSinceValue(value) {            
            this.employed_since = value
        },
        updateEmployedToValue(value) {            
            this.employed_to = value
        },
        onSubmit() {
            this.$v.$touch()
            this.focusFirstError()
            if (!this.$v.$invalid) {
                this.$store.dispatch("event", { name: "IncomeDataSubmit" });
                this.$store.dispatch("sendData", { step: "dati_lav" });

                this.$store.commit('setInsuranceAvailable', this.insuranceAvailable)
                this.$store.commit('setInsurancePack', this.insurancePackage)       
                this.$store.commit('setInsuranceRate', this.insuranceRate)           
                
                if (this.insuranceAvailable) {
                    this.$router.push({ name: "LoanProtection" });
                    return;
                }
                if ([JOB_TYPES.AUTONOMO, JOB_TYPES.DIPENDENTE, JOB_TYPES.STAGIONALE, JOB_TYPES.PROFESSIONISTA].includes(this.employment_type)) {                    
                    this.$store.dispatch("redirect", { step: "dati_lav", route: "EmployerData" });                    
                } else {
                    this.$store.dispatch("redirect", { step: "dati_lav", route: "PaymentDetails" });                    
                }
            }
        }
    }
};
</script>