import Vue from 'vue';

Vue.directive("uppercase", {
  componentUpdated: function(el) {
    el.value = el.value.toLocaleUpperCase();
    el.dispatchEvent(new CustomEvent("input"));
  }
});
Vue.directive("clear-regex", {
  update: function(el, bind) {
    el.value = el.value.replace(bind.value, "");
    el.dispatchEvent(new CustomEvent("input"));
    //el.dispatchEvent(new Event('input'))
  }
});