<template>
    <div data-model="importo_2" action="next" method="POST" class="my-4 sm:my-8 proposals text-gray-700 lg:text-xl xl:text-2xl">
        <div class="offers">
            <div class="text-center lg:text-left px-4 py-1  sm:px-16">
                <h1 class="text-xl sm:text-3xl font-semibold">{{ $t("Ora scopri la soluzione ideale") }}</h1>
                <h2 class="text-base lg:mt-4">{{ $t("Scegli l’importo, la rata e la durata che preferisci") }}</h2>
            </div>
            <div class="bg-yellow text-center lg:text-left py-1 mt-4">
                <h3 class="text-base mx-auto px-4 py-1 sm:px-16">{{ $t("La nostra proposta di prestito personale") }}</h3>
            </div>
            <div class="px-2 sm:px-16 sm:px-4 mx-auto lg:container pt-6">            
                <ul class="mb-4 lg:mb-6">
                    <ProposalOur class="our" :importo="importo" :active="proposalOurActive" @selected="toggleSelected"></ProposalOur>
                </ul>
                <h3 class="text-base mb-4 lg:mb-6 mt-10 sm:mt-6 text-center lg:text-left" v-if="list.length">{{ $t("Altre proposte di prestito personale") }}</h3>
                <ul class="lg:mb-6">
                    <ProposalOther class="others" v-for="model in list" :key="model.rate" :model="model" :active="selected == `${importo}x${model.rate}`" @selected="toggleSelected"></ProposalOther>
                </ul>
            </div>
        </div>

        <portal to="sub-form" v-if="$route.name == 'Rata' && option1">
            <div class="limited-offer w-11/12 sm:max-w-7xl mx-auto shadow-lg pt-2 rounded-3xl bg-limited-offer px-2 sm:px-16 -mt-6 mb-10">
                <div class="px-2 mx-auto lg:container pt-2 sm:pt-5 pb-2">
                    <h2 class="mb-2 sm:mb-4 lg:mb-6 text-white text-sm lg:text-3xl font-semibold justify-center lg:justify-start flex items-center flex-wrap">
                        <div class="mr-2 mb-2 sm:mb-0 w-full sm:w-auto text-center">
                            <img class="h-7 mx-auto" src="@/assets/images/rata-clock.svg" />
                        </div>
                        <p>{{ $t("Offerta limitata, solo 100 prestiti personali") }}</p>
                    </h2>
                    <ul>
                        <ProposalLimited v-if="option1" :importo="option1" :active="ProposalLimited1Active" @selected="toggleSelected" class="mb-2 lg:mb-8"></ProposalLimited>
                        <ProposalLimited v-if="option2" :importo="option2" :active="ProposalLimited2Active" @selected="toggleSelected" class="mb-2 lg:mb-8"></ProposalLimited>
                    </ul>
                </div>
            </div>
        </portal>
    </div>
</template>
<script>
import ProposalOur from "@/components/ProposalOur";
import ProposalLimited from "@/components/ProposalLimited";
import ProposalOther from "@/components/ProposalOther";
export default {
    components: { ProposalOther, ProposalOur, ProposalLimited },    
    data() {
        return {          
            selected: this.$route.name === "Rata" ?
                this.$store.state.choosenCredit :
                this.$store.state.limitedCredit,
            flashTimeout: null
        }
    },
    mounted() {
        this.showFlashMessage()
    },
    beforeDestroy() {
        this.resetFlashMessage()
    },
    computed: {
        proposalOurActive() {                            
            if(this.$route.name == 'RataUpgrade' && window.innerWidth >= 1024) return true
            return this.selected == this.importo
        },
        ProposalLimited1Active() {
            return window.innerWidth < 1024 && this.selected == this.option1
        },
        ProposalLimited2Active() {
            return window.innerWidth < 1024 && this.selected == this.option2
        },
        active() { 
            return this.$route.name === "Rata" ?
                this.$store.state.choosenCredit :
                this.$store.state.limitedCredit
        },
        importo() {
            return this.$route.name === "Rata" ?
                this.$store.state.choosenCredit :
                this.$store.state.limitedCredit;
        },
        option1() {
            const importo = this.importo + 1000;
            return this.$store.getters.rateOffers({ importo: importo }).length ? importo : null
        },
        option2() {
            const importo = this.importo + 2000;
            return this.$store.getters.rateOffers({ importo: importo }).length ? importo : null
        },
        list() {
            // fire pageview event
            this.$ga.pageview()

            return this.$store.getters.rateOffers({
                importo: this.importo,
                justone: false
            });
        },
        flashMessageShowed() {
            return this.$store.state.flashMessageShowed
        },
    },
    methods: {
        showFlashMessage() {
            if (!this.flashMessageShowed) {
                const vm = this
                const message = 'Affrettati! Un utente ha ricevuto esito positivo alla sua richiesta di prestito proprio ora.'
                this.flashTimeout = window.setTimeout(function() {
                    vm.$store.commit("setFlashMessage", message)
                }, 5000)
            }
        },
        resetFlashMessage() {
            window.clearTimeout(this.flashTimeout)
            this.$store.commit("resetFlashMessage");
        },
        toggleSelected(value) {
            if(window.innerWidth < 1024) this.selected = value            
        }
    }
};
</script>