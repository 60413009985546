<template>
    <footer class="bg-gray-4 text-white">
        <div class="w-11/12 py-6 mx-auto sm:max-w-7xl relative">
            <a href="#" @click.prevent="scrollToTop()" class="absolute shadow-arrow rounded-full bg-white bg-center bg-no-repeat w-12 h-12 right-0 top-0 -mt-6 mr-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline-block text-gray-500 h-8 mt-2 ml-2">
                    <path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
            </a>
            <div class="footer-logo">
                <img class="w-36" src="@/views/themes/prestito/images/logo-claim-white.png" />
            </div>
            <div class="footer-content mt-4">
                <h4 class="text-base font-semibold mb-5 sm:text-2xl">
                    Informazioni legali
                </h4>
                <p class="mb-5">
                    Per tutte le condizioni economiche e contrattuali, fare riferimento alle Informazioni Europee di Base sul Credito ai Consumatori (cd. <a href="https://cofidis-secci.s3.eu-central-1.amazonaws.com/revolving/prod/SECCI_REV_1120_compilato_no_promo.pdf" class="underline" target="_blank">SECCI</a>) sul sito <a href="https://www.cofidis.it/trasparenza/" class="underline" target="_blank">www.cofidis.it/trasparenza</a>.<br> 
                    Esempio rappresentativo con TAEG calcolato sulla base delle ipotesi che l’Importo totale del credito, fornito per un anno, sia utilizzato da subito e per intero al primo utilizzo; che sia applicato unicamente il Tasso Standard e non anche quello promozionale; che il Contratto resti valido e che il consumatore adempia ai propri obblighi nei termini ed entro le date convenute nel Contratto; che siano pagate rate mensili conteggiate suddividendo l’importo totale del credito in 12 quote uguali (quota capitale costante); ad ogni quota è aggiunta una componente di interessi variabile maturata mensilmente e calcolata sul capitale residuo riferito al periodo precedente, sulla base del TAN FISSO - Importo totale del credito €2.000 - rate minime mensili €60 da rimborsare mediante addebito in conto corrente - TAN fisso standard 19,80% - TAEG 21,70%. Spese di istruttoria €0; spese di incasso rata €0; imposta di bollo sul contratto/imposta sostitutiva €0; spese di invio Rendiconto mensile €0; imposta di bollo su Rendiconto mensile €0. Importo totale dovuto €2.214,50. La richiesta di finanziamento e di ogni utilizzo successivo sono soggette alla valutazione da parte di Cofidis S.A.
                </p>
                <p class="my-3">
                    <b>Cofidis Italia</b><br />&copy; Copyright {{ year }}
                </p>
                <p class="mb-3">
                    Capitale Sociale Euro 67.500.000,00 i.v. - Società soggetta a direzione e coordinamento di Banque Fédérative du Crédit Mutuel S.A – soggetta alla vigilanza della Banca di Francia (Autorité de Contrôle Prudentiel et de Résolution) e, in qualità di succursale italiana di banca comunitaria, alla vigilanza della Banca d’Italia, per le materie a questa demandate dalle vigenti disposizioni normative.
                </p>
                <a href="https://www.cofidis.it/it/privacy-policy.html" class="underline" target="_blank">Privacy Policy</a>
            </div>
        </div>
    </footer>
</template>
<script>
import $ from "jquery";
export default {
    data() {
        return {
            start_date: null,
            end_date: null,
            year: null,
        };
    },
    async mounted() {
        $(document).on("click", ".to-top", function() {
            $("html, body").animate({ scrollTop: 0 }, 1000);
            return false;
        });
        const data = await $.getJSON(
            "https://cofidis-settings.s3.eu-central-1.amazonaws.com/prestitocofidis.json"
        );
        this.start_date = data.Start_Date;
        this.end_date = data.End_Date;
        this.year = new Date().getFullYear();
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
    },
};
</script>