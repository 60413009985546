import loadData from "@/dataService";
const config = loadData("config.json");
const enabled = config.tracking.cxmtrxq ? config.tracking.cxmtrxq.enabled : false;
const account = config.tracking.cxmtrxq ? config.tracking.cxmtrxq.id : -1;

function cxmtrxq(userData) {
    if (!enabled) {
        return;
    }

    window._cxmtrxq = [];
    window._cxmtrxq.push(["attr", "UniqueID", userData.orderID]);
    window._cxmtrxq.push([
        "attr",
        "Name",
        userData.name + " " + userData.surname
    ]);
    window._cxmtrxq.push([
        "attr",
        "Address1",
        userData.address + " " + userData.address_number
    ]);
    window._cxmtrxq.push([
        "attr",
        "Address2",
        userData.document_number //This is probably a bug
    ]);
    window._cxmtrxq.push(["attr", "PostalCode", userData.address_cap]);
    window._cxmtrxq.push(["attr", "City", userData.address_city]);
    window._cxmtrxq.push(["attr", "State", "Italy"]);
    window._cxmtrxq.push(["attr", "Country", "IT"]);
    window._cxmtrxq.push(["attr", "Email", userData.email]);
    window._cxmtrxq.push(["attr", "Phone", userData.cellphone]);

    var server = "global.clixmetrix.com";
    window._cxmtrxq.push(["server", server]);
    var se = document.createElement("script");
    se.type = "text/javascript";
    se.async = true;
    se.src =
        ("https:" == document.location.protocol ? "https://" : "http://") +
        server +
        "/" +
        account +
        "/";
    var ses = document.getElementsByTagName("script")[0];

    if (process.env.NODE_ENV === "development") {
        console.log("Mock clixmetrix tracking", se.src)
    } 
    else { 
        ses.parentNode.insertBefore(se, ses);
    }
}

export default cxmtrxq;