<template>
    <main>
        <!-- Header -->
        <header class="w-full relative" :style="bgHeader">
            <!-- Side band -->
            <div class="band w-2 h-full absolute bg-yellow-3"></div>
            <!-- Logo -->
            <div class="logo flex justify-end container mx-auto px-6 max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                <div class="z-50 bg-white rounded-b-2xl py-2 px-4 md:px-4 lg:-mr-6 2xl:-mr-24 shadow-sm">
                    <img class="w-20 sm:hidden" src="@/views/themes/prestito/images/logo.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                    <img class="w-40 hidden sm:block" src="@/views/themes/prestito/images/logo-claim.svg" alt="Cofidis Logo" title="Cofidis Logo" />
                </div>
            </div>
            <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl px-6 2xl:px-0 mt-28 sm:mt-0">
                <div class="px-4 md:px-14 lg:px-2">                       
                    <h1 class="w-80 slanted sm:ml-4 px-0 sm:px-4 uppercase">prestito personale</h1>
                    <br class="hidden sm:block">
                    <h1 class="w-32 slanted ml-2 px-2 sm:pr-4 uppercase">cofidis</h1>                
                </div>
                <div class="mb-2 md:px-14 px-4 lg:px-2">
                    <p class="text-xl font-light text-white md:text-3xl md:mb-8 inline-block py-1 pr-4">
                        I tuoi progetti,<br class="block sm:hidden"> la nostra fiducia
                    </p>
                </div>
            </div>
            <div class="md:container mx-auto text-center px-6 sm:px-10 md:px-6">
                <div class="bg-white rounded-3xl shadow-2xl px-4 mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <p class="text-xl text-gray-5 font-semibold pt-4 sm:pt-10 pb-2 sm:mb-4 px-4 text-2xl sm:px-8 lg:text-3xl">
                        Richiedilo in tre minuti,<br class="sm:hidden"> ovunque tu sia
                    </p>
                    <!-- Form importo -->
                    <FormImporto />
                    <h2 class="mt-6 sm:mt-8 text-xl text-gray-5 font-light">
                        Richiedi il prestito semplice,<br class="block sm:hidden"> veloce e 100% online
                    </h2>
                    <div class="flex flex-wrap items-start py-4 sm:py-4 lg:px-40">
                        <div class="w-1/6 lg:hidden"></div>
                        <!-- Icons -->
                        <div class="w-1/3 md:w-2/5 lg:w-1/4 text-center my-4">
                            <i class="icon flash align-middle w-10 h-10 block rounded-full mb-1 sm:mt-0 inline-block"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2">
                                Prevalutazione <br class="sm:hidden" />
                                immediata
                            </h4>
                        </div>
                        <div class="w-1/3 md:w-1/5 lg:w-1/4 text-center my-4">
                            <i class="icon rata align-middle w-10 h-10 block rounded-full mb-1 sm:mt-0 inline-block"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2">Rata fissa</h4>
                        </div>
                        <div class="w-1/6 lg:hidden"></div>
                        <div class="w-1/6 lg:hidden"></div>
                        <div class="w-1/3 md:w-2/5 lg:w-1/4 text-center my-4">
                            <i class="icon zero align-middle w-10 h-10 block rounded-full mb-1 sm:mt-0 inline-block"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2">Zero spese</h4>
                        </div>
                        <div class="w-1/3 md:w-1/5 lg:w-1/4 text-center my-4">
                            <i class="icon phone align-middle w-10 h-10 block rounded-full mb-1 sm:mt-0 inline-block"></i>
                            <h4 class="block text-sm xl:inline-block lg:px-2">
                                Tutto <br class="sm:hidden" />
                                online
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </header>

         <!-- Section steps -->
        <section class="section-steps bg-gray-5 pt-10 pb-10">
            <div class="container mx-auto max-w-7xl">
                <!-- Title -->
                <div class="text-center mb-8 sm:mb-14">
                    <h3 class="font-semibold text-3xl text-white md:text-4xl">
                        Il tuo Prestito Personale, <br class="sm:hidden" />
                        in 3 semplici passi
                    </h3>
                </div>
                <!-- Steps -->
                <div class="mb-4 sm:mb-10 flex flex-wrap">
                    <!-- Step 1 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-12 sm:px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__1">
                            <span class="block font-bold text-sm text-gray-4 mb-2">Step 1</span>
                            <p class="font-semibold text-3xl text-red-1">Scegli l’importo</p>
                            <p class="font-light text-gray-4 mt-2">Richiedi da 3000€ a 30.000€ tramite il form di richiesta</p>
                        </div>
                    </div>
                    <!-- Step 2 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-12 sm:px-6 sm:h-40 lg:h-auto
                            animate__animated animateFadeInUp animate__2">
                            <span class="block font-bold text-sm text-gray-4 mb-2">Step 2</span>
                            <p class="font-semibold text-3xl text-red-1">
                                Esito immediato
                            </p>
                            <p class="font-light text-gray-4 mt-2">
                                Ricevi subito la prima risposta e l'esito finale in 24h
                            </p>
                        </div>
                    </div>
                    <!-- Step 3 -->
                    <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/4 lg:flex lg:flex-1">
                        <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-12 sm:px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__4">
                            <span class="block font-bold text-sm text-gray-4 mb-2">Step 3</span>
                            <p class="font-semibold text-3xl text-red-1">Firma con un sms</p>
                            <p class="font-light text-gray-4 mt-2">
                                Firma il contratto direttamente dal tuo smartphone
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Button scrollToTop -->
                <div class="text-center">
                    <button class="font-semibold text-lg text-gray-4 bg-orange-1 uppercase max-w-xs rounded-full shadow-2xl px-8 py-3 sm:max-w-none sm:w-auto animate__animated animateFadeInUp animate__5" @click.prevent="scrollToTop()">
                        Scegli un importo
                    </button>
                </div>
            </div>
        </section>

        <!-- Section media -->
        <section class="py-10 md:py-16 lg:py-20">
            <!-- Title -->
            <div class="text-center mb-8 sm:mb-14">
                <h3 class="font-semibold text-3xl text-gray-5 leading-none md:text-4xl"> Come funziona</h3>
            </div>
            <div class="w-full px-6 sm:w-3/5 flex items-center justify-center mx-auto relative">
                <YouTubeInline :videoId="`wESNxK_CJjo`" ref="videoComeFunziona" :height="`500px`"/>
            </div>
        </section>

     
        <!-- Section banner -->
        <section :style="bgBanner" class="section-banner py-10 md:py-16 lg:py-20">
            <div class="container mx-auto flex flex-wrap">
                <div class="w-full lg:w-1/2 text-center px-6 mb-8 md:inline-block md:px-2 lg:mb-0">
                    <div class="bg-orange-1 rounded-3xl shadow-2xl px-10 sm:px-4 py-4 mx-auto xl:w-3/4">
                        <div class="">
                            <p class="text-xl text-gray-5 font-semibold mb-6 sm:mb-10 px-4 lg:text-3xl sm:px-8">
                                Abbiamo dato fiducia a
                            </p>
                        </div>
                        <div class="w-full pb-5 mb-5 border-b-2 border-gray-700 border-opacity-30 sm:p-0 sm:w-1/2 sm:border-b-0 sm:border-r-2 sm:inline-block">
                            <img class="mx-auto h-14" alt="Group" title="Group" src="@/views/themes/prestito/images/credito/cofidis-milioni-clienti.svg" />
                            <h3 class="lg:mt-2">
                                <strong class="font-semibold text-2xl text-gray-4 sm:text-3xl lg:text-4xl block">30 milioni</strong>
                                <span class="text-lg uppercase"> di clienti </span>
                            </h3>
                        </div>
                        <div class="w-full sm:w-1/2 sm:inline-block">
                            <img class="mx-auto h-14" alt="Pin" title="Pin" src="@/views/themes/prestito/images/credito/cofidis-nove-paesi-europa.svg" />
                            <h3 class="lg:mt-2">
                                    <strong class="font-semibold text-2xl text-gray-4 sm:text-3xl lg:text-4xl block">in 9 paesi</strong>
                                <span class="text-lg uppercase"> in Europa </span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 text-center px-6 md:inline-block md:px-2">
                    <div class="bg-orange-1 rounded-3xl shadow-2xl px-10 sm:px-4 pt-4 pb-3 mx-auto xl:w-3/4">
                        <div class="">
                            <p class="text-xl text-gray-5 font-semibold mb-4 px-4 lg:text-3xl sm:px-8">
                                Con ottimi risultati
                            </p>
                        </div>
                        <div class="flex flex-wrap items-center sm:pb-4">
                            <div class="google-rating-wrapper">
                                <img class="mx-auto w-36" alt="Prestito Personale – Google Rating" title="Google Rating" :src="`${commonDistributionUrl}/Google_rating_stelle_bianche.png`" />
                            </div>
                            <div class="w-full sm:w-1/2 sm:inline-block sm:px-4">
                                <img class="mx-auto" alt="Prestito Personale – TrustPilot 2022" title="TrustPilot 2022" :src="`${commonDistributionUrl}/TrustPilot_Cofidis_2022.png`" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Section cofidis leader -->
        <section class="section-leader bg-gray-5 py-8">
            <div class="container mx-auto">
                <div class="w-full relative flex flex-wrap items-center justify-center lg:h-48">
                    <img class="sm:mr-10 h-40" alt="Prestito Personale – Cofidis Certificazione Ottima Finanziaria" title="Logo Certificazione Ottima Finanziaria" :src="`${commonDistributionUrl}/ottima_finanziaria_2022.png`" />
                    <h3 class="w-full sm:w-auto font-semibold text-2xl text-white leading-none md:text-3xl lg:text-4xl mt-6 sm:mt-0 text-center">Cofidis è il leader del credito a distanza</h3>
                </div>
            </div>
        </section>
       
        <!-- Section widget -->
        <section class="section-widget md:mt-0 p-6 sm:py-16 bg-white">
            <div class="container mx-auto">
                <!-- Title -->
                <div class="text-center mb-8 sm:mb-16">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none md:text-4xl">
                        Cosa dicono i <br class="sm:hidden" />
                        nostri clienti
                    </h3>
                </div>
                <!-- TrustBox widget - Carousel -->
                <div class="trustpilot-widget" data-locale="it-IT" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="5d0a61ad3bcbc70001701a39" data-style-height="240px" data-style-width="100%" data-theme="Organization" data-stars="4,5" data-review-languages="it">
                  <a href="https://it.trustpilot.com/review/www.prestitocofidis.com" target="_blank" rel="noopener">Trustpilot</a>
                </div>
                <!-- End TrustBox widget -->                
            </div>
        </section>
       <!-- Section faq -->
        <section class="section-faq py-10 bg-gray-1 md:py-16">
            <div class="sm:container px-6 mx-auto">
                <h3 class="font-semibold text-3xl text-gray-5 leading-none px-6 mb-10 text-center lg:text-4xl">
                    Trova la risposta <br class="sm:hidden" />
                    alle tue domande
                </h3>
                <div class="faq-container w-full flex flex-wrap hover:text-orange cursor-pointer mb-5" :class="{ hidden: i > 3 && !allFaqs }" v-for="(faq, i) in faqs" :key="i">
                    <div class="h-10 w-10 text-center rounded-full leading-10 mr-5 -mt-2 outline-none flex items-center justify-center" 
                    :class="{
                        'bg-gray-2': activeFaq != i,
                        'bg-orange-1 shadow-xl': activeFaq == i
                    }"
                    @click="toggleFaq(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="text-white h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" :class="{'transform rotate-180': activeFaq != i}">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                        </svg>
                    </div>
                    <div class="w-4/5">
                        <div class="faq-question mb-4 outline-none" :class="{ active: activeFaq == 1 }" @click="toggleFaq(i)">
                            <span class="font-semibold text-2xl text-gray-5 leading-none">{{
                                faq.q
                                }}</span>
                        </div>
                        <SlideUpDown class="faq-content mb-4 font-medium text-gray-5 cursor-default outline-none" :active="activeFaq == i"><span class="font-light text-lg text-gray-5" v-html="faq.a"></span></SlideUpDown>
                    </div>
                </div>
                <div class="text-center" v-if="allFaqs" @click="allFaqs = false">
                    <p class="inline font-semibold text-lg text-red-1 text-center cursor-pointer mt-6 uppercase border-b-4 border-red-1">
                        Mostra meno
                    </p>
                </div>
                <div v-else class="text-center" @click="allFaqs = true">
                    <p class="inline font-semibold text-lg text-red-1 text-center cursor-pointer mt-6 uppercase border-b-4 border-red-1">
                        Carica altre
                    </p>
                </div>
            </div>
        </section>
        <!-- Section amount -->
        <section class="section-amount bg-gray-5 py-10">
            <div class="container mx-auto px-2 sm:px-10 text-center">
                <h3 class="font-semibold text-3xl text-white leading-tight px-8 mb-4 sm:px-0 md:text-4xl">
                    Stai pensando <br class="sm:hidden" />
                    a una cifra?
                </h3>
                <p class="font-light text-lg text-white mb-5 lg:text-xl">
                    Ok, scegli l'importo qui sotto
                </p>
                <FormImporto class="mx-4" />
            </div>
        </section>
        <!-- Section logos -->
        <section class="section-logos">
            <div class="container flex flex-wrap items-center mx-auto px-8 py-10 md:py-16 lg:px-32">
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto w-40" alt="Prestito Personale – Google Rating" title="Google Rating" :src="`${commonDistributionUrl}/Google_rating_stelle_gialle.png`" />
                </div>
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto w-52" alt="Prestito Personale – Best Workplaces" title="Logo Best Workplaces" :src="`${commonDistributionUrl}/logo_best_workplaces.png`" />
                </div>
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto w-24 lg:w-32" alt="Prestito Personale – Cofidis Certificazione Ottima Finanziaria" title="Logo Certificazione Ottima Finanziaria" :src="`${commonDistributionUrl}/ottima_finanziaria_2022.png`" />
                </div>
                <div class="w-1/2 text-center mb-6 sm:w-1/4 sm:mb-0">
                    <img class="mx-auto" alt="Prestito Personale – TrustPilot 2022" title="TrustPilot 2022" :src="`${commonDistributionUrl}/TrustPilot_Cofidis_2022.png`" />
                </div>
            </div>
        </section>

        <YouTubeModal :videoId="`wESNxK_CJjo`" ref="videoCosaServe" />
        <YouTubeModal :videoId="`6f43JlNpZbQ`" ref="videoFirmaDigitale" />
    </main>
</template>
<script>
// @ is an alias to /src
import FormImporto from "@/components/FormImporto.vue";
import Swiper from "swiper";
import YouTubeInline from "@/views/themes/prestito/YouTubeInline";
import YouTubeModal from "@/views/themes/prestito/YouTubeModal";

export default {
    name: "Home",
    components: {
        FormImporto,
        YouTubeInline,
        YouTubeModal
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
        toggleFaq(i) {
            if (this.activeFaq == i) this.activeFaq = null;
            else this.activeFaq = i;
        },
    },
    computed: {
        bgHeader() {
            const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'            
            return {
                '--bg-header': `#fff url("${this.distributionUrl}/${env}/header.jpg") 55% 0% no-repeat`,
                '--bg-header-sm': `#fff url("${this.distributionUrl}/${env}/header-sm.jpg") center top no-repeat`,
            }
        },
        bgBanner() {
            return {
                '--bg-banner': `#fff url("${this.distributionUrl}/credito-cofidis-banner-sm.jpg") 60% top no-repeat`,
                '--bg-banner-sm': `#fff url("${this.distributionUrl}/credito-cofidis-banner.jpg") 60% top no-repeat`,
            }
        }
    },
    data() {
        return {
            rating: 0,
            ratingsTotal: 0,
            activeFaq: null,
            allFaqs: false,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/prestito",
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            faqs: [{
                    q: "Cosa serve per richiedere un Prestito Personale Cofidis online?",
                    a: `<span class="sm:mr-2">Per richiedere un Prestito Personale Cofidis online inserisci i tuoi dati personali, incluso il tuo codice fiscale e l’IBAN del tuo conto corrente bancario.</span>                    
                        <button id="playVideoCosaServe" class="inline-block font-medium text-lg text-red-1 text-center cursor-pointer  border-b-2 border-red-1 cursor-pointer">Guarda il video</button>`
                },
                {
                    q: "Dove trovo il mio IBAN?",
                    a: `Trovi il tuo IBAN nel servizio Home Banking della tua banca (sia web che app), sull’estratto conto fornito dalla tua banca.`,
                },
                {
                    q: "Cosa vuol dire prevalutazione immediata?",
                    a: `Dopo l’invio della richiesta di Prestito Personale Cofidis, ti comunicheremo subito via e-mail se sarà stata valutata positivamente e, in seguito, ti invieremo, sempre via e-mail, il contratto.`,
                },
                {
                    q: "Quanto posso chiedere?",
                    a: "Puoi chiedere un importo fino a 30.000 € e lo puoi restituire fino a 84 mesi.",
                },
                {
                    q: "Cos’è un contratto con firma digitale?",
                    a: `<span class="sm:mr-2">È un contratto che ricevi via e-mail e puoi firmare con una OTP (One Time Password) cioè un codice numerico che ricevi via SMS sul tuo smartphone.</span>                    
                        <button id="playVideoFirmaDigitale" class="inline-block font-medium text-lg text-red-1 text-center cursor-pointer  border-b-2 border-red-1 cursor-pointer">Guarda il video</button>`
                },
                {
                    q: "Quali documenti devo allegare al contratto di Prestito Personale Cofidis?",
                    a: "Basta un documento di identità a scelta fra Carta d’Identità, Patente, Passaporto. Inoltre è necessaria la tessera sanitaria, un documento che attesti il tuo reddito e il frontespizio IBAN fornito dalla tua banca (per verificare che l’IBAN inserito in fase di richiesta sia corretto e poterti effettuare l’accredito sul conto corrente).",
                },
                {
                    q: "Quando ricevo la somma di denaro richiesta?",
                    a: "Dopo la verifica da parte dei nostri analisti del contratto e della documentazione allegata, avrai l’esito della pratica entro 24 ore. In caso di esito positivo, riceverai la somma di denaro richiesta entro 48 ore sul tuo conto corrente.",
                },
                {
                    q: "Qual è il metodo di pagamento per pagare le rate?",
                    a: "Addebito automatico sul tuo conto corrente (SDD).",
                },
            ],
        };
    },
    created() {        
        const vm = this;
        // Embed YouTube player if Cookie preferences allows it
        window.event.$on('cookiePreferencesUpdated', () => {
            console.log('cookiePreferencesUpdated')
            if (!window.dataLayer) return false;   

            for (var i in window.dataLayer) {                
                if (window.dataLayer[i].event && window.dataLayer[i].event == "OneTrustLoaded") {
                    const consentGroups = window.dataLayer[i].OnetrustActiveGroups.split(',')
                    // Third party cookie consent
                    if (consentGroups && consentGroups.includes('C0004')) {                        
                        let script = document.createElement("script");
                        script.src = "//www.youtube.com/iframe_api";
                        window.onYouTubeIframeAPIReady = function() {
                            vm.$refs.videoComeFunziona.init()
                            vm.$refs.videoFirmaDigitale.init()
                            vm.$refs.videoCosaServe.init()
                        };
                        document.head.appendChild(script);
                        
                        return true;
                    }                                     
                }
            }            
        })
    },
    mounted() {
        const vm = this;
        
        document.getElementById('playVideoFirmaDigitale').addEventListener("click", function() {
            vm.$refs.videoFirmaDigitale.play()  
        });   
        document.getElementById('playVideoCosaServe').addEventListener("click", function() {
            vm.$refs.videoCosaServe.play()  
        });

        // load trustpilot script
        let script = document.createElement("script");
        script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        document.head.appendChild(script);
        //reload widgets
        document.querySelectorAll(".trustpilot-widget").forEach(function(widget) {
            if (window.Trustpilot) window.Trustpilot.loadFromElement(widget);
        });

        new Swiper("#trustpilot-sw.swiper-container", {
            slidesPerView: 1,
            spaceBetween: 30,
            pagination: {
                el: "#trustpilot-sw .swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: "#trustpilot-sw .swiper-button-next",
                prevEl: "#trustpilot-sw .swiper-button-prev",
            },
        });

        // animation interesection
        window.onscroll = function() {
            const animateFadeInUp = document.getElementsByClassName("animateFadeInUp");
            //const animateFadeIn = document.getElementsByClassName("animateFadeIn");
            const windowOffsetTop = window.innerHeight + window.scrollY;

            Array.prototype.forEach.call(animateFadeInUp, (animatedBox) => {
                const animatedBoxOffsetTop = animatedBox.offsetTop;
                if (windowOffsetTop >= animatedBoxOffsetTop) {
                    animatedBox.classList.add('animate__fadeInUp')                     
                }
            });            
        }
    }
};
</script>