<template>
    <div class="w-40 xl:w-48 mx-auto text-center text-black">
        <img src="@/views/themes/prestito/images/credito/google.png" class="w-12 h-12 mx-auto" />
        <h4 class="text-lg font-extralight mt-1">Google Rating</h4>
        <div class="flex flex-wrap justify-center space-x-2 items-center">
            <div class="text-2xl font-semibold mt-1">{{ rating }}</div>
            <div>
                <StarRating :inline="true" :rating="rating" :read-only="true" :increment="0.01" :show-rating="false" :star-size="24" :active-color="activeColor"></StarRating>
            </div>
        </div>
        <a class="block text-sm mt-1 font-extralight" href="https://www.google.com/search?q=cofidis&safe=strict&rlz=1C5CHFA_enIT953IT953&sxsrf=ALeKk01BHdSVpDNW7jhFuyXsYupMZurMGA%3A1624876002897&ei=4qPZYJ-TNvP97_UP2PWUsA8&oq=cofidis&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyBAgjECcyBAgjECcyBAgAEEMyBQgAELEDMgIIADIICC4QxwEQrwEyBQgAELEDMgQIABBDMgIIADoHCAAQRxCwAzoNCC4QxwEQrwEQsAMQQzoHCAAQsAMQQ0oECEEYAFC6tQ5YurUOYOa3DmgCcAJ4AIAB0AGIAfsCkgEFMC4xLjGYAQCgAQGqAQdnd3Mtd2l6yAEKwAEB&sclient=gws-wiz&ved=0ahUKEwifjNPnjrrxAhXz_rsIHdg6BfYQ4dUDCA4&uact=5#lrd=0x4786c6673e700001:0x1f9598c6ff61f29e,1,,," target="_blank">Read our {{ ratingsTotal }} reviews</a>
    </div>
</template>
<script>
import StarRating from "vue-star-rating";
export default {
    name: "GoogleRating",
    props: {
        rating: {
            type: Number,
            required: true,
        },
        ratingsTotal: {
            type: Number,
            required: true,
        },
        activeColor: {
            type: String,
            required: false,
            default: '#fff'
        }
    },
    components: {
        StarRating,
    },
};
</script>